import Upbond, { UPBOND_BUILD_ENV } from '@upbond/upbond-embed';
import Web3 from 'web3';
import { providers, utils } from 'ethers';
import { numberToStringer, toWei } from '../utils/index';

import {
  lineLoginConfig,
  networkConfig,
  walletThemeConfig,
  googleLoginConfig,
} from '../utils/EmbedUtils';
const { signERC2612Permit } = require('eth-permit');
class UpbondEmbed {
  // Initials
  upbond = new Upbond({});

  web3 = new Web3();

  // you can also using another envs.
  env = `${process.env.REACT_APP_EMBED_BUILD_ENV}`;

  provider = null;

  isLoggedIn = false;

  initialized = false;

  constructor() {
    this.upbond = new Upbond({
      buttonPosition: 'bottom-left',
      buttonSize: -10,
    });
    this.provider = null;
  }

  async init() {
    if (this.upbond instanceof Upbond) {
      await this.upbond.init({
        isUsingDirect: true,
        skipDialog: false,
        enableLogging: false,
        widgetConfig: {
          showAfterLoggedIn: false, //show or not show embed button after login
          showBeforeLoggedIn: false,
        },
        buildEnv: UPBOND_BUILD_ENV.STAGING, //use production if you wanna using upbond wallet production
        dappRedirectUri: `${window.location.origin}/upbond-success`,
        loginConfig: {
          'upbond-line': lineLoginConfig,
          'upbond-google': googleLoginConfig,
        },
        whiteLabel: {
          walletTheme: walletThemeConfig,
        },
        showUpbondButton: true,
        network: networkConfig,
      });
      this.initialized = true;
      this.provider = upbondServices.upbond.provider;
    }
  }

  async login() {
    try {
      if (this.upbond instanceof Upbond && this.web3 instanceof Web3) {
        const _provider = await this.upbond.login(); // login using upbond
        this.web3.setProvider(this.upbond.provider);

        const accounts = await this.web3.eth.getAccounts();

        this.isLoggedIn = true;
        this.provider = _provider;
        return {
          msg: 'success',
          data: _provider,
          accounts,
          // ... anything that you want to returns
        };
      }
    } catch (error) {
      return {
        msg: error.message || 'Failed to login',
        data: null,
      };
    }
  }

  async logout() {
    try {
      if (this.upbond instanceof Upbond) {
        await this.upbond.logout();
        await this.upbond.cleanUp();
        // window.location.reload();

        return {
          msg: 'success',
          data: true,
        };
      }
    } catch (error) {
      return {
        msg: error.message || 'Failed to login',
        data: null,
      };
    }
  }

  async getUserInfo() {
    if (this.upbond instanceof Upbond) {
      try {
        const userInfo = await this.upbond.getUserInfo();
        return userInfo;
      } catch (error) {
        throw new Error(error);
      }
    }
  }

  async signTransaction(msg = '', account) {
    if (this.web3 instanceof Web3) {
      try {
        this.web3.setProvider(this.upbond.provider);
        const sign = await this.web3.eth.sign(msg, account);
        return sign;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }

  async permit(amount) {
    console.log('permit()');
    if (this.web3 instanceof Web3) {
      try {
        await this.web3.setProvider(this.upbond.provider);
        const provider = new providers.Web3Provider(this.upbond.provider);
        const addresses = await this.web3.eth.getAccounts();
        const address = addresses[0];
        const tokenAddress = process.env.REACT_APP_BRONCOSCOIN_CONTRACT_ADDRESS || '';
        const spender = process.env.REACT_APP_DEPLOYER_ADDRESS || '';

        console.log('amount = ', amount);
        console.log('typeof amount = ', typeof amount);

        const parseEtherAmount = utils.parseEther(amount).toString()
        console.log('parseEtherAmount = ', parseEtherAmount);

        // if (await isValidBalance(address, parseEtherAmount )) {
        //   throw new Error('Amount about to Permit is higher than the balance');
        // }

        const result = await signERC2612Permit(provider, tokenAddress, address, spender, parseEtherAmount);

        console.log('result = ', result);
        return result;
      } catch (error) {
        console.error('error = ', error);
        return null;
      }
    }
  }
}

const upbondServices = new UpbondEmbed();

export default upbondServices;
