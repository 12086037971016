import { t } from 'i18next';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { useIonViewWillEnter } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';

import { fetchUserData } from '../services';
import { useToast } from '../hooks/useToast';
import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { TitleComponent, Button, Loader, ProfileData, Toast } from '../components';

const ContentWrapper = styled.div`
  padding: 0 20px;
  hr {
    margin: 0;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 25px;
  .button-section {
    padding-top: 1rem;
    text-align: center;
    > ion-button {
      margin-bottom: 1.7rem;
    }
    .btn {
      width: 140px;
    }

    @media (max-width: 430px) {
      > ion-button {
        font-size: 16px;
      }
    }

    @media (max-width: 390px) {
      > ion-button {
        font-size: 14px;
      }
    }

    @media (max-width: 345px) {
      > ion-button {
        font-size: 13px;
      }
    }
  }
  .home-button {
    padding-bottom: 25px;
  }
`;

const EditButtonWrapper = styled.div`
  padding: 40px 0px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  ion-button {
    width: 140px;
  }
  .personal-info {
    width: 150px;
  }
`;

const Profile: React.FC = () => {
  const { push } = useHistory();
  const [fieldData, setFieldData] = useState<any>({});
  const { userProfileData, teamDetails } = useContext(UserContext);

  // Extract the code from the URL query parameters
  const params = new URLSearchParams(location.search);
  const message = params.get('message');

  const [showToast, setShowToast] = useToast(false);
  const [toastType, setToastType] = useState<'Error' | 'Success'>('Error');
  const [toastMessage, setToastMessage] = useState<any>('Something went wrong. Please try again.');

  const COLOR = teamDetails?.team.teamColor;

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['fetchUserData', userProfileData?.id],
    fetchUserData,
    {
      enabled: !!userProfileData?.id,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (response) => {
        return response.data;
      },
    }
  );

  useIonViewWillEnter(() => {
    userProfileData?.id !== undefined && refetch();
  }, [userProfileData, isFetching]);

  useEffect(() => {
    setFieldData({
      name_mei: data?.name_mei,
      name_sei: data?.name_sei,
      iconUrl: data?.iconUrl,
      furigana_mei: data?.furigana_mei,
      furigana_sei: data?.furigana_sei,
      email: data?.email,
      birthday: data?.birthday,
      postcode: data?.postcode,
      mailPostcode: data?.mailPostcode,
      address: data?.address,
      address1: data?.address1,
      mailAddress: data?.mailAddress,
      mailAddress1: data?.mailAddress1,
      phone: data?.phone,
      nickname: data?.nickname,
      gender: data?.gender,
      occupation: data?.occupation,
      termsAndCondition: '同意する',
    });
  }, [data]);

  useEffect(() => {
    if (message) {
      if (message === 'Discordのロール付与が完了しました。') {
        setToastType('Success');
        setToastMessage(message);
        setShowToast(true);
      } else {
        setToastType('Error');
        setShowToast(true);
        setToastMessage(
          `認証に失敗しました。<br />右下のCHATアイコンからDiscordに参加後、再度認証してください。`
        );
      }
    }
  }, [location.search]);

  return (
    <Wrapper authenticated>
      {showToast && <Toast message={t(toastMessage)} type={toastType} />}

      <Loader
        isLoading={isLoading || isFetching || userProfileData?.id === undefined}
        className="home-loader"
      >
        <ContentWrapper>
          <TitleComponent
            title
            heading="Edit Profile"
            subHeading="個人情報変更画面"
            color={COLOR}
          />
          <ProfileData fieldData={fieldData} color={COLOR}>
            <ButtonWrapper>
              <div className="button-section">
                <Button
                  size="large"
                  color={COLOR}
                  onClick={() =>
                    push({
                      pathname: `/profile/${userProfileData?.id}`,
                    })
                  }
                >
                  {t('Update profile')}
                </Button>
                <Button size="large" color={COLOR} onClick={() => push('/membership-card-info')}>
                  {t('Update membership expiry date')}
                </Button>
                <Button
                  expand="block"
                  size="small"
                  className="btn"
                  color={COLOR}
                  onClick={() => push('/account-delete-confirmation')}
                >
                  {t('Withdrawal')}
                </Button>
              </div>
            </ButtonWrapper>
          </ProfileData>
          <EditButtonWrapper>
            <Button size="small" color={COLOR} onClick={() => push('/settings')}>
              {t('Back to setting')}
            </Button>
          </EditButtonWrapper>
        </ContentWrapper>
      </Loader>
    </Wrapper>
  );
};

export default PrivateRoute(Profile);
