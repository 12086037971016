import { IonText } from '@ionic/react';
import styled from 'styled-components';
import React, { useContext } from 'react';

import { Button } from '../../atoms';
import { UserContext } from '../../../utils/UserContext';

interface LevelProgressBarProps {
  level: number;
  value: number;
  totalPoint: number;
  receivedPoint: number;
  color: string;
}

const ProgressButton = styled(Button)`
  opacity: 1;
  ::part(native) {
    width: 295px;
    height: 295px;
    border-radius: 50%;
    margin: auto;

    @media (max-width: 375px) {
      width: 250px;
      height: 250px;
    }
  }
`;
const CircularProgressBar = styled.div<any>`
  --value: ${(props) => props.value};
  --pgPercentage: var(--value);
  --fg: ${(props) => (props.color ? props.color : 'black')};
  --bg: #eee;
  animation: progressBarAnimation 3s 1 forwards;
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(closest-side, var(--bg) 94%, transparent 0 98.5%, transparent 0),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), transparent 0);
  position: relative;
  ::after {
    content: '';
    position: absolute;
    height: calc(15rem / 2 + 10px);
    width: calc(10 / 100 * 15rem + 20px);
    top: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(calc(var(--pgPercentage) / 100 * 360deg));
    transform-origin: 50% 100%;
    animation: progressBarAnimation 3s 1 forwards;
    z-index: 1;
    background-image: radial-gradient(var(--fg) 0 50%, transparent 50% 100%);
    background-repeat: no-repeat;
    background-position: 0px 0;
    background-size: 27px 27px;
  }
  @keyframes progressBarAnimation {
    0%,
    33% {
      --pgPercentage: 0;
    }
    100% {
      --pgPercentage: var(--value);
    }
  }

  @property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
  }

  @media (max-width: 375px) {
    width: 12.7rem;
    height: 12.7rem;

    ::after {
      height: calc(12.7rem / 2 + 9px);
      width: calc(10 / 100 * 12.7rem + 5px);
    }
  }
`;
const CircularBar = styled.div`
  position: absolute;
  z-index: -1;
  border: 1px solid #c9c9c9;
  filter: blur(0.8px);
  width: 14.7rem;
  height: 14.7rem;
  border-radius: 50%;
  box-shadow: 1px 2px 2px #ffffff inset, inset 2px 1px 5px #ffffff;
  filter: blur(1px);

  @media (max-width: 375px) {
    width: 12.5rem;
    height: 12.5rem;
  }
`;
const ContentBar = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  .points {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 18px;
    color: #4d4d4d;
    .received-point {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
    @media (max-width: 375px) {
      font-size: 10px;
      .received-point {
        font-size: 20px;
      }
    }
  }
  .level {
    font-size: 35px;
    font-weight: 700;
    color: ${(props) => props.color};

    @media (max-width: 375px) {
      font-size: 26px;
    }
  }

  .level-left {
    margin-top: 20px;
    font-size: 13px;
    line-height: 14px;

    @media (max-width: 375px) {
      font-size: 12px;
    }
  }
`;

const LevelProgressBar: React.FC<LevelProgressBarProps> = ({
  level,
  value,
  totalPoint = 10000,
  receivedPoint,
  color,
}) => {
  const { status, teamDetails } = useContext(UserContext);
  const levelUpto = status?.levelTo;
  const currentLevel = teamDetails?.level;

  return (
    <ProgressButton disabled>
      <CircularProgressBar
        aria-valuenow={isNaN(value) ? 0 : value}
        aria-valuemin="0"
        aria-valuemax="100"
        value={isNaN(value) ? 0 : value}
        color={color}
      >
        <CircularBar />
        <ContentBar color={color}>
          <IonText className="points">
            {receivedPoint !== null ? <span className="received-point">{receivedPoint}</span> : ''}
            <b>coin</b>/{totalPoint.toLocaleString('en-US')}coin
          </IonText>
          <IonText className="level">Lv.{level}</IonText>
          <span className="level-left">
            次のステータスアップまで
            <br />
            {levelUpto - currentLevel} レベル
          </span>
        </ContentBar>
      </CircularProgressBar>
    </ProgressButton>
  );
};

export { LevelProgressBar };
