import { Hub } from 'aws-amplify';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useIonViewWillLeave } from '@ionic/react';

import { AuthContext } from '../utils/AuthContext';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { IonPageComponent, Loader } from '../components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMembershipExpiration } from '../hooks/useMembershipExpiration';

const LoaderComponent = styled(Loader)`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
`;

const AuthProgressPage: React.FC = () => {
  const { upbondProviders, cognitoUser, logout } = useContext(AuthContext);
  const { decodedData, userProfileData, expiryDate } = useContext(UserContext);
  const [haveCognitoError, setCognitoError] = useState(false);
  const history = useHistory();
  const unsubscribe = useRef<any>(null);

  const { isExpired } = useMembershipExpiration();

  const handleCleanup = async () => {
    await logout();
    setCognitoError(false);
    history.push('/login-fail');
  };

  useEffect(() => {
    // NOTE: User logged in is only true when: IsInitialized && haveCognitoError && currentVerifier && isLoggedIn are all true
    if (
      upbondProviders?.isInitialized &&
      haveCognitoError &&
      upbondProviders?.isLoggedIn &&
      upbondProviders?.currentVerifier
    ) {
      handleCleanup();
      return;
    }
    if (upbondProviders?.isInitialized && !upbondProviders?.isLoggedIn && cognitoUser === null) {
      history.push('/login-fail');
    }
    if (cognitoUser && decodedData) {
      if (userProfileData) {
        if (userProfileData?.username) {
          if (
            sessionStorage.getItem('redirect_to') &&
            sessionStorage.getItem('redirect_to')?.includes('/market/purchase-confirmation')
          ) {
            const redirect_url = sessionStorage.getItem('redirect_to');
            window.location.href = `${redirect_url}`;
          } else if (expiryDate && expiryDate !== null) {
            if (isExpired) {
              window.location.href = '/membership-card-info';
            } else {
              window.location.href = '/home';
            }
          }
        } else if (!userProfileData?.username && sessionStorage.getItem('redirect_to')) {
          const redirect_url = sessionStorage.getItem('redirect_to')?.split('/').slice(3).join('/');
          window.location.href = `${redirect_url}`;
        } else {
          window.location.href = '/team-list';
        }
      }
    }
  }, [
    upbondProviders?.isInitialized,
    upbondProviders?.isLoggedIn,
    upbondProviders?.currentVerifier,
    haveCognitoError,
    sessionStorage,
    userProfileData,
    cognitoUser,
    decodedData,
    expiryDate,
    isExpired,
  ]);

  useEffect(() => {
    unsubscribe.current = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn_failure':
          if (data?.message === 'attributes+required%3A+%5Bemail%5D') {
            localStorage.setItem('emailStatus', 'false');
            window.location.replace(
              'https://26f22c82.auth.dev.upbond.io/v2/logout?returnTo=https://staging.fanique.io&client_id=Fm8fZJjJrGgs3uxAv6voH'
            );
          }
          setCognitoError(true);
          break;
        case 'cognitoHostedUI_failure':
          if (data?.message === 'attributes+required%3A+%5Bemail%5D') {
            localStorage.setItem('emailStatus', 'false');
            window.location.replace(
              'https://26f22c82.auth.dev.upbond.io/v2/logout?returnTo=https://staging.fanique.io&client_id=Fm8fZJjJrGgs3uxAv6voH'
            );
          }
          setCognitoError(true);
          break;
        default:
          break;
      }
    });
  }, []);

  // TODO: Remove the Hub subscribe if HUB is used in other places
  useIonViewWillLeave(() => {
    unsubscribe.current();
  }, []);

  return (
    <IonPageComponent>
      <LoaderComponent isLoading={true}>
        <Wrapper />
      </LoaderComponent>
    </IonPageComponent>
  );
};

export default AuthProgressPage;
