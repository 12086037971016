import styled from 'styled-components';
import React, { useContext } from 'react';
import { useIonViewDidEnter } from '@ionic/react';

import { UserContext } from '../../../utils/UserContext';
import { AuthContext } from '../../../utils/AuthContext';
import { Footer, Header, IonPageComponent, Loader } from '../../../components';
interface Props {
  authenticated?: boolean;
  noScrollToTop?: boolean;
  scrollTop?: boolean;
  setScrollTop?: React.Dispatch<React.SetStateAction<boolean>>;
  translate?: boolean;
  children?: any;
}

const HomeWrapper = styled.div<any>`
  position: ${(props) => (props?.isAuthenticated ? 'relative' : null)};
  min-height: ${(props) => (props?.isAuthenticated ? '100vh' : null)};
  #team-select-section {
    padding: 3rem 2rem 0.5rem 2rem;
  }
  #team-collection-section {
    margin-bottom: 2.6rem;
  }
  #owner-section {
    padding: 1.5rem 1rem 1rem 1rem;
  }
  .divider {
    margin: 0px 2.5rem;
  }
  .content {
    left: 0px;
    right: 0px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: ${(props) => (props.isAuthenticated ? '25px' : null)};
    min-height: ${(props) =>
      props.isAuthenticated || (props.loggedin && props.isFirstStepCompleted && !props.emptyMember)
        ? '79vh'
        : '53vh'};
  }
  .content::-webkit-scrollbar {
    display: none;
  }
  .footer-wrapper {
    bottom: -44px !important;
    padding: ${(props) => (props.isAuthenticated ? '3px 0' : null)};
    position: ${(props) =>
      props.isAuthenticated || (props.loggedin && props.isFirstStepCompleted && !props.emptyMember)
        ? 'sticky'
        : null};
    bottom: ${(props) => (props.isAuthenticated ? '0' : null)};
    width: ${(props) => (props.isAuthenticated ? '414px' : null)};
    @media (max-width: 480px) {
      width: ${(props) => (props.isAuthenticated ? '100%' : null)};
    }
    background: transparent;
    z-index: 100;
  }
  #sticky-header {
    position: sticky;
    top: 0;
    background-color: #eeeeee;
    z-index: 999;
  }
  .home-loader {
    margin-top: 55%;
  }
`;

const PAGETITLE: any = {
  '/top': 'Top画面',
  '/login-progress': 'Fanique',
  '/status-up': 'ステータスアップ画面',
  '/status-up-confirm': 'ステータアップ確認画面',
  '/status-up-complete': 'ステータアップ完了画面',
  '/welcome': 'チーム決定①',
  '/home': 'チームHOME',
  '/membership-rank': '会員ランク選択',
  '/team-list': {
    base: 'チーム一覧',
    withId: '保有チーム選択画面',
  },
  '/rank-details': { withId: 'ランク特典詳細' },
  '/terms-and-conditions': '利用規約',
  '/privacy-policy': 'プライバシーポリシー',
  '/inquiry': 'お問い合わせ',
  '/inquiry-sent': 'お問い合わせ送信完了',
  '/nft-generation': 'メンバーシップNFT生成画面',
  '/membership-confirmation': 'メンバーシップ購入確認',
  '/voting-list': '投票一覧',
  '/voting-distribution': '投票分布 ',
  '/logout': 'ログアウト',
  '/my-page': 'マイページ',
  '/membership-card-info': {
    base: '年会費支払い画面 ',
    withId: 'メンバーシップ有効期限確認・チャージ画面',
  },
  '/membership-card-purchase-success': '年会費チャージ完了画面',
  '/profile': { base: '個人情報,決済情報確認/変更画面', withId: '個人情報,決済情報確認/変更画面' },
  '/personal-information-confirmation': '個人情報登録内容確認画面',
  '/how-to-use': '使い方',
  '/about': 'Faniqueを知る',
  '/settings': '設定 ',
  '/qr-code': 'QR画面',
  '/transaction-history': '取引/コイン獲得履歴',
  '/personal-information': '個人情報登録画',
  '/specified-commercial-transactions': '特定商取引に関する法律に基づく表記',
  '/level-up-success': 'レベルアップ確認画面',
  '/level-up': 'レベルアップ画面',
  '/level-up-confirmation': 'レベルアップ確認画面',
  '/account-delete-confirmation': '退会確認画面',
  '/withdrawl-confirmation': '退会最終確認画面',
  '/withdrawl-completed': '退会完了画面',
  '/rank-benefit': 'ランク特典詳細',
  '/withdrawl-careful': '退会注意事項画面',
  '/publish-team': 'Teamを掲載する',
  '/notification': {
    base: 'お知らせ詳細 ',
    withId: 'お知らせ詳細',
  },
  '/team-detail': 'チーム詳細',
  '/submission-completed': 'Teamを掲載_完了',
  '/404': '404',
  '/market': {
    base: 'マーケット',
    withId: 'マーケット',
  },
  '/other-information': 'その他の情報',
};

const LoaderComponent = styled(Loader)`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
`;

const Wrapper: React.FC<Props> = ({
  authenticated = false,
  noScrollToTop,
  scrollTop,
  setScrollTop,
  translate = true,
  children,
}) => {
  const { teamDetails, isFirstStepCompleted, isLoading, userProfileData } = useContext(UserContext);
  const { loading, authenticated: loginAuthentication }: any = useContext(AuthContext);
  useIonViewDidEnter(() => {
    const pathname = window.location.pathname;
    if (pathname !== '/') {
      if (pathname.split('/').length > 2) {
        if (pathname.split('/')[2] === 'team-list') {
          document.title = 'チーム一覧 - Fanique';
        } else if (pathname.split('/')[2] === 'favourite-list') {
          document.title = 'お気に入り - Fanique';
        } else {
          const path = `/${pathname.split('/')[1]}`;
          document.title = `${PAGETITLE[path]?.withId} - Fanique`;
        }
      } else {
        if (PAGETITLE[pathname]?.base) {
          document.title = `${PAGETITLE[pathname].base} - Fanique`;
        } else {
          if (pathname === '/top') document.title = `Fanique`;
          else document.title = `${PAGETITLE[pathname]} - Fanique`;
        }
      }
    }
  }, [window.location.pathname]);

  const membershipStatus = userProfileData?.membershipStatus?.items?.length === 0;

  return (
    <IonPageComponent
      noScrollToTop={noScrollToTop}
      scrollTop={scrollTop}
      setScrollTop={setScrollTop}
    >
      <LoaderComponent isLoading={loading || isLoading} className="loader">
        <HomeWrapper
          isAuthenticated={authenticated}
          loggedin={loginAuthentication}
          isFirstStepCompleted={isFirstStepCompleted}
          emptyMember={membershipStatus}
        >
          <Header
            authenticated={authenticated}
            color={authenticated ? teamDetails?.team?.teamColor : ''}
          />
          <div className="content">{children}</div>
          <div className="footer-wrapper">
            <Footer
              authenticated={authenticated}
              color={teamDetails?.team?.teamColor}
              translate={translate}
            />
          </div>
        </HomeWrapper>
      </LoaderComponent>
    </IonPageComponent>
  );
};

export default Wrapper;
