import styled from 'styled-components';
import { useHistory } from 'react-router';
import { closeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { IonModal, IonIcon } from '@ionic/react';
import React, { useContext, useState } from 'react';

import { API } from '../services/api';
import { useToast } from '../hooks/useToast';
import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, TitleComponent, Toast } from '../components';
import { useMembershipExpiration } from '../hooks/useMembershipExpiration';

const Container = styled.div`
  text-align: center;
  min-height: 35em;

  .page-title {
    font-weight: 350;
    font-size: 16px;
  }
  .title-container {
    line-height: 0px !important;
  }
  .title {
    font-weight: 800;
    margin-top: -0.3em;
    font-size: 30px !important;
  }
  .options-button {
    margin: 35px 28px;
    font-size: 17.5px;

    @media (max-width: 390px) {
      font-size: 16px;
    }

    @media (max-width: 375px) {
      font-size: 14px;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .back-button {
    margin-top: 1.2em;
    width: 10em;
  }
`;

const IonModalComponent = styled(IonModal)`
  --width: 326px;
  --height: 262px;
  --border-radius: 10px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.2);

  .ion-delegate-host {
    background-color: #efefef;
  }

  .close-btn-modal {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 20px;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 33px 30px;

    p {
      font-weight: 400;
      font-size: 14px;
    }

    .cancel {
      font-size: 18px;
      cursor: pointer;
    }

    ion-icon {
      align-self: center;
      font-size: 45px;
      fill: ${(props) => props?.color && props?.color};
    }
  }
`;

const CustomButton = styled(Button)`
  width: 265px;
  color: ${(props) => (props?.disabled ? '#cccccc' : props?.color && props?.color)};
`;

const Settings: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { teamDetails, status, userProfileData } = useContext(UserContext);

  const [showToast, setShowToast] = useToast(false);
  const [isDiscordLoading, setIsDiscordLoading] = useState(false);
  const [showDiscordModal, setShowDiscordModal] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<any>('Something went wrong. Please try again.');

  const { isExpired } = useMembershipExpiration();

  const closeModal = () => {
    setShowDiscordModal(false);
  };

  const handleLogout = () => {
    // logout functions here
    history.push('/logout');
  };

  const color = teamDetails?.team?.teamColor;

  const goToAuthentication = async () => {
    setIsDiscordLoading(true);
    try {
      const response = await API.get(
        `/discordauth?userId=${userProfileData?.id}&teamId=${teamDetails?.team?.id}`
      );

      if (response) {
        const newTab = window.open(`${response?.data?.url}`, '_blank');

        if (newTab) {
          // Pop-up not blocked
          newTab.focus();
        } else {
          // Pop-up blocked
          setToastMessage(
            'Please allow pop-ups for this website from browser setting to continue.'
          );
          setShowToast(true);
        }
      }
    } catch (_) {
      setToastMessage('Something went wrong. Please try again.');
      setShowToast(true);
    }
    setShowDiscordModal(false);
    setIsDiscordLoading(false);
  };

  const otherInfoTerm = () => {
    if (status?.statusNameEnglish !== 'RED' && status?.statusNameEnglish !== 'SILVER') {
      return (
        <Button
          size="large"
          className="options-button"
          color={color}
          onClick={() => history.push('/other-information')}
        >
          {t('Team info confirm')}
        </Button>
      );
    }
  };

  return (
    <Wrapper authenticated={true}>
      {showToast && <Toast message={t(toastMessage)} type="Error" />}

      <IonModalComponent
        isOpen={showDiscordModal}
        onDidDismiss={closeModal}
        color={teamDetails?.team?.teamColor}
      >
        <IonIcon icon={closeOutline} className="close-btn-modal" onClick={() => closeModal()} />
        <div className="body">
          <IonIcon src="/assets/icon/discord-logo.svg" />
          <p>{t('Redirect to Discord')}</p>
          <CustomButton
            disabled={isDiscordLoading}
            size="large"
            className="btn"
            onClick={goToAuthentication}
            color={teamDetails?.team?.teamColor}
          >
            {t('Go to authentication')}
          </CustomButton>
          <p className="cancel" onClick={closeModal}>
            {t('Cancel')}
          </p>
        </div>
      </IonModalComponent>

      <Container>
        <div className="title-container">
          <p className="page-title">{t('Settings')}</p>
          <TitleComponent title={true} heading="Settings" className="title" color={color} />
        </div>
        <Button
          size="large"
          className="options-button"
          color={color}
          onClick={() => history.push('/profile')}
        >
          {t('Personal & payment info')}
        </Button>

        {isExpired ? (
          <Button disabled size="large" className="options-button" color={color}>
            {t('Discord Auth')}
          </Button>
        ) : (
          <Button
            size="large"
            className="options-button"
            color={color}
            onClick={() => setShowDiscordModal(true)}
          >
            {t('Discord Auth')}
          </Button>
        )}
        <Button
          size="large"
          className="options-button"
          color={color}
          onClick={() => history.push('/membership-card-info')}
        >
          {t('Membership card info')}
        </Button>
        {otherInfoTerm()}
        <Button
          size="large"
          className="options-button"
          onClick={() => handleLogout()}
          color={color}
        >
          {t('Logout')}
        </Button>
        <div className="button-container">
          <Button
            size="small"
            className="back-button"
            onClick={() => history.push('/my-page')}
            color={color}
          >
            {t('Back')}
          </Button>
        </div>
      </Container>
    </Wrapper>
  );
};

export default PrivateRoute(Settings);
