import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { publishTeam } from '../services';
import { useToast } from '../hooks/useToast';
import RestrictedRoute from '../withRestrictedRoute';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { TitleComponent, Divider, StepComponent, Toast } from '../components';

const PublishTeam = styled.div`
  color: #4d4d4d;

  .divider {
    margin-bottom: 30px;
  }

  .title {
    font-size: 30px;
    font-weight: 900;
    color: #4d4d4d;
    line-height: 43px;
    text-align: center;
    margin-top: 20px;
  }

  .about {
    padding-bottom: 30px;
    .desc {
      text-align: center;
      font-weight: 400;
      font-size: 15px;
      margin-top: 25px;
      padding: 0 30px 00px 30px;
    }
  }

  .point {
    padding: 0 30px 30px 30px;

    .list-wrapper {
      margin-top: 30px;

      .title-wrapper {
        display: flex;
        align-items: flex-start;

        .count {
          min-height: 43px;
          min-width: 43px;
          border-radius: 50%;
          box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf, inset -3px -4px 4px -4px #a7a4a4,
            inset 3px 5px 9px -7px #f9e6e6;
          justify-content: center;
          display: flex;
          padding: 9px;
          color: #29c8d2;
          font-weight: 900;
          font-size: 25px;
          margin-right: 10px;
          background-color: #eee;
        }

        .header {
          font-weight: 700;
          font-size: 20px;
          line-height: 29px;
        }
      }

      .detail {
        font-weight: 400;
        font-size: 15px;
        text-align: center;
      }
    }
  }
`;

const pointData = [
  {
    id: 1,
    header: 'スポーツチームがメンバーシップNFTを活用したファンクラブを作成可能',
    detail:
      'スポーツチームは、メンバーシップNFTをFanique上で発行して、ファンコミュニティを運営していくことができます。ファンの応援熱量が可視化されたFaniqueによって新しいファンコミュニケーションが可能となります。',
  },
  {
    id: 2,
    header: 'チームの意思決定への参画',
    detail:
      'メンバーシップNFTのステータスによっては、ファンが経営に近い立場で意思決定に関与することができます。また、公式ファングッズデザイン、ユニフォームデザイン等の意思決定にも参画することが可能です。 （上位ステータスのメンバーは、チームの意思決定により深く関与できます) ',
  },
  {
    id: 3,
    header: 'チームへの応援を価値に変えることができる、Support & Earn リワードプログラム',
    detail:
      'チームを応援（試合観戦、グッズ購入等）することでファンはコインを獲得し、メンバーシップをレベルアップ、ステータスアップさせることができます。その結果、チームへの影響度を上げることに繋がります。メンバーシップNFTは、価値を持つ公式アイテムとして、スカラが運営するマーケットプレイスの他、外部のサードパーティーが運営するマーケットプレイスでも売買可能です。',
  },
];

const PublishTeamPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [showToast, setShowToast] = useToast(false);

  const [data, setData] = useState<any>({
    teamName: '',
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    primaryAddress: '',
    secondaryAddress: '',
    extPhone: '',
    firstPhone: '',
    secondPhone: '',
  });

  const { mutate, isLoading } = useMutation(publishTeam, {
    onSuccess: () => {
      history.push({
        pathname: `/submission-completed`,
      });
      setData({
        teamName: '',
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        primaryAddress: '',
        secondaryAddress: '',
        extPhone: '',
        firstPhone: '',
        secondPhone: '',
      });
    },
    onError: () => {
      setShowToast(true);
    },
  });

  return (
    <Wrapper>
      {showToast && <Toast message={t('Something went wrong. Please try again.')} type={'Error'} />}

      <PublishTeam>
        <p className="title">{t('Publish team')}</p>
        <Divider className="divider" />
        <div className="about">
          <TitleComponent title heading="About" subHeading="Overview" />
          <p className="desc">
            Faniqueは、NFTとファンクラブの機能を融合した、 新しい形態のファンクラブサービスです。
            スポーツチームは、 メンバーシップNFTを発行することができます。
            メンバーシップNFTを活用することで、 従来のファンクラブの機能に加え、
            チームの重要な意思決定に関与することや チームや選手への応援量が価値となり
            所有することが可能になります。 当サービスでは、 「Support and Earn」をコンセプトに掲げ、
            試合を観戦してチームを応援することで、 メンバーシップNFTのレベルやステータス
            をアップさせることができます。 ファンは、好きなチームを選んで応援し、 チームの力になり、
            その貢献を価値に変えることができます。
          </p>
        </div>
        <Divider className="divider" />
        <div className="point">
          <TitleComponent title heading="Features" subHeading="feature" className="title" />
          <div className="list-wrapper">
            {pointData.map((item) => {
              return (
                <div key={item.id}>
                  <div className="title-wrapper">
                    <div className="count">{item.id}</div>
                    <div className="header">{item.header}</div>
                  </div>
                  <p className="detail">{item.detail}</p>
                </div>
              );
            })}
          </div>
        </div>
        <Divider className="divider" />
        <StepComponent
          onSubmitHandler={mutate}
          isLoading={isLoading}
          data={data}
          setData={setData}
        />
      </PublishTeam>
    </Wrapper>
  );
};

export default RestrictedRoute(PublishTeamPage);
