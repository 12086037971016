import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import React, { useContext, useEffect } from 'react';

import { useToast } from '../hooks/useToast';
import { useIonViewDidEnter } from '@ionic/react';
import { UserContext } from '../utils/UserContext';
import { AuthContext } from '../utils/AuthContext';
import RestrictedRoute from '../withRestrictedRoute';
import { useLikingCardId } from '../hooks/useLikingCardId';
import { fetchMarketList, likeMarketCard } from '../services';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import {
  Toast,
  Divider,
  Request,
  Roadmap,
  TeamSelect,
  OwnerSection,
  MarketCarouselSection,
  TopBackgroundComponent,
} from '../components';

const RoadmapWrapper = styled.div`
  .divider {
    margin: 2rem 2.5rem;
  }
`;

const RequestWrapper = styled.div`
  margin-top: 2rem;

  .divider {
    margin: 3rem 2.5rem;
  }
`;

/* remove this after market feature is available */

const NotAvailable = styled.div`
  position: relative;

  ::after {
    content: '';
    padding: 40px 0;
    position: absolute;
    top: -33px;
    width: 100%;
    height: 90%;
    background-color: #e2e1e1;
    opacity: 0.7;
    z-index: 10;
  }

  h1 {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 100%;
    text-align: center;
    font-weight: 800;
    font-size: 30px;
    z-index: 11;
    transform: translateY(-80%) rotate(-35deg);
  }
`;

const HOME_PAGE_MAIN_BG = '/assets/images/bg_1.png';
const LOGO = '/assets/images/logo.png';
const HEADING = 'ファンを超えて、その先へ';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const [showToast, setShowToast] = useToast(false);
  const { upbondProviders, cognitoUser } = useContext(AuthContext);
  const { userProfileData, teamListData, teamListLoading, teamListFetching } =
    useContext(UserContext);

  // NFT Market API
  const {
    data: marketList,
    isFetching: loadingMarketList,
    refetch,
  } = useQuery(['fetchMarketList', 1, 'desc', 'createdAt'], fetchMarketList, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  // Like Market Card API
  const { likingCardId, setLikingCardId } = useLikingCardId();
  const { mutate: likeCard, isLoading: isLiking } = useMutation(likeMarketCard);

  const handleLike = (nftMasterId?: string, teamId?: string) => {
    if (userProfileData?.id)
      likeCard({ nftMasterId, userId: userProfileData?.id, teamId: teamId! });
  };

  useEffect(() => {
    (async function () {
      if (
        cognitoUser === null &&
        upbondProviders &&
        upbondProviders.isInitialized &&
        upbondProviders.isLoggedIn &&
        upbondProviders.currentVerifier
      ) {
        await upbondProviders.cleanUp();
      }
    })();
  }, [
    upbondProviders,
    upbondProviders?.isInitialized,
    upbondProviders?.isLoggedIn,
    upbondProviders?.currentVerifier,
  ]);

  useEffect(() => {
    const emailStatus = localStorage.getItem('emailStatus');
    if (emailStatus && emailStatus === 'false') {
      setShowToast(true);
      localStorage.removeItem('emailStatus');
    }
  }, [localStorage]);

  // Refetch to get new likes
  useIonViewDidEnter(() => {
    refetch();
  }, []);

  return (
    <Wrapper authenticated={false}>
      {showToast && <Toast message={t('Email address is missing')} type={'Error'} />}
      <TopBackgroundComponent bgImg={HOME_PAGE_MAIN_BG} logo={LOGO} heading={HEADING}>
        メンバーシップNFTでファンとチームの
        <br />
        新次元の繋がりと価値を育てよう
      </TopBackgroundComponent>
      <div id="team-select-section">
        <TeamSelect data={teamListData} isLoading={teamListLoading || teamListFetching} />
      </div>
      <Divider className="divider" />
      {/* <NotAvailable> */}
      {/* replace <NotAvailable> with <div> after market feature is available */}
      {/* <h1>Coming soon</h1> */}
      <MarketCarouselSection
        data={marketList?.data?.utilityNfts?.map((d) => {
          // after randomUtilityNfts has items field
          /* let finalImage: string = '';
          if (d?.isRandomTemplate === true) {
            finalImage = d?.randomUtilityNfts?.items![0].templateFileUrl ?? '';
          } else {
            finalImage = d?.templateFileUrl ?? '';
          } */
          return {
            teamName: d?.team?.teamName,
            teamLogo: d?.team?.primaryLogoImagePath,
            // after randomUtilityNfts has items field
            /* image: finalImage, */
            image: d?.templateFileUrl,
            title: d?.cardName,
            price: `${d?.price?.toLocaleString()}円`,
            quantity: `${d?.currentSoldNumber?.toString()}/${d?.maxSalesNumber?.toString()}`,
            id: d?.id,
            likesNumber: d?.favorites?.items ? d?.favorites?.items?.length : 0,
            favorites: d?.favorites?.items,
            teamId: d?.team?.id,
          };
        })}
        loading={loadingMarketList}
        onLikeClick={handleLike}
        isLiking={isLiking}
        likingCardId={likingCardId}
        setLikingCardId={setLikingCardId}
      />
      {/* </NotAvailable> */}
      <div id="owner-section">
        <OwnerSection />
      </div>
      <RoadmapWrapper>
        <Divider className="divider" />
        <Roadmap />
      </RoadmapWrapper>
      <Divider className="divider" />
      <RequestWrapper>
        <Request />
        <Divider className="divider" />
      </RequestWrapper>
    </Wrapper>
  );
};

export default RestrictedRoute(Home);
