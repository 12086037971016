import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../utils/AuthContext';
import { UserContext } from '../utils/UserContext';
import {
  fetchMarketBanners,
  fetchMarketFavouriteListAPI,
  fetchMarketList,
  likeMarketCard,
} from '../services';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import {
  Loader,
  Button,
  Divider,
  Dropdown,
  SearchBar,
  MarketCard,
  Pagination,
  FilterModal,
  TitleComponent,
  MarketBannerCarousel,
  MarketCarouselSection,
} from '../components';
import RestrictedRoute from '../withRestrictedRoute';
import { useLikingCardId } from '../hooks/useLikingCardId';
import { useIonViewDidEnter } from '@ionic/react';

const MarketWrapper = styled.div`
  padding: 0px 25px;
  .market-divider {
    margin: 40px 0px 11px 0px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 25px;
  margin-bottom: 33px;
  ion-button {
    width: 140px;
    height: 28px;
    font-size: 12px;
    text-transform: capitalize;
  }
  .button-down {
    border-radius: var(--border-radius);
    --box-shadow: var(--shadow-down);
  }

  @media (max-width: 414px) {
    padding: 0 20px;
  }

  @media (max-width: 390px) {
    padding: 0 10px;
  }

  @media (max-width: 370px) {
    padding: 0px;
  }

  @media (max-width: 350px) {
    ion-button {
      width: 45%;
    }
  }
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 30px 30px 30px;
  .btn {
    width: 104px;
  }
`;

const FavouriteListWrapper = styled.div`
  padding: 0px 26px 32px 26px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-row-gap: 17px;
  grid-column-gap: 5px;

  ion-card {
    width: 100%;
  }
`;

const MarketListLoader = styled(Loader)`
  padding-bottom: 32px;
`;

const Message = styled.span`
  font-size: 14px;
  display: flex;
  justify-content: center;
`;

const PaginationWrapper = styled.div<{ totalPage?: string }>`
  padding: 0px 40px;
  margin-bottom: ${(props) => (props?.totalPage === '1' ? '0' : '35px')};
`;

// For Filters
export interface FilterType {
  tag?: string;
  nftFileType?: string;
  saleStatus?: string;
}

const initialFilters = [
  // { name: 'tag', value: ['バスケ'] },
  { name: 'tag', value: '' }, // use empty initial value for now, will update as API updates
  { name: 'nftFileType', value: 'Image' },
  { name: 'saleStatus', value: 'all' },
  { name: 'paymentCurrency', value: '円' },
  { name: 'team', value: ['さいたまブロンコス'] },
  { name: 'playerName', value: '' },
];

// const initialFilters = {
//   tag: 'バスケ',
//   nftFileType: 'Image',
//   // status: '',
//   // paymentCurrency: '',
//   // team: [],
// };

// For Banners
interface Banner {
  id: string;
  imageUrl: string;
  linkUrl: string;
}
type Banners = Banner[];

const NFTMarket = () => {
  const { t } = useTranslation();
  // Filter
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  // Sorting
  const TOTAL_PAGE = '3';
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [sortField, setSortField] = useState<'createdAt' | 'playerName' | 'price'>('createdAt');
  const [sortValue, setSortValue] = useState<'new' | 'price' | 'playerNameAsc' | 'playerNameDesc'>(
    'new'
  );
  //For Main Search
  const [isMainSearch, setIsMainSearch] = useState(false);
  // Pagination
  const [totalPages, setTotalPages] = useState('1');
  const [currentPage, setCurrentPage] = useState(1);
  const { authenticated } = useContext(AuthContext);
  const { isFirstStepCompleted, teamDetails, userProfileData } = useContext(UserContext);

  // Filters
  /* 
  Note: We have to set the default filters for radio & checkbox here too because state updates are slower compared to
  radio & checkbox buttons setting using default values at first. 
  */
  const [filters, setFilters] = useState<any>(initialFilters);

  // Banners
  const [banners, setBanners] = useState<Banners>([]);
  const { data: bannerData, isFetching: loadingBanners } = useQuery(
    'fetchMarketBanners',
    fetchMarketBanners,
    {
      enabled: !authenticated || (authenticated && userProfileData?.username === null),
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
    }
  );
  // When user is logged in, call the api again but with teamId
  const { data: bannerDataAfterAuth, isFetching: loadingBannersAfterAuth } = useQuery(
    ['fetchMarketBannersAfterAuth', teamDetails?.team?.id],
    fetchMarketBanners,
    {
      enabled: teamDetails?.team?.id ? true : false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
    }
  );

  const {
    data: favouriteData,
    isFetching: favouriteFetching,
    refetch: refetchFavoritesData,
  } = useQuery(
    ['fetchFavouriteData', userProfileData?.id, teamDetails?.team?.id, 1],
    fetchMarketFavouriteListAPI,
    {
      enabled: userProfileData?.id ? true : false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  // NFT Market API
  const {
    data: marketList,
    isFetching: loadingMarketList,
    refetch,
  } = useQuery(
    ['fetchMarketList', currentPage, sortDirection, sortField, searchText, filters, isMainSearch],
    fetchMarketList,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess(data) {
        setTotalPages(data.data.total?.toString() || '1');
      },
    }
  );

  // Like Market Card API
  const { likingCardId, setLikingCardId } = useLikingCardId();
  const { mutate: likeCard, isLoading: isLiking } = useMutation(likeMarketCard, {
    onSuccess: () => {
      setLikingCardId('');
    },
  });

  const options = [
    {
      label: t('New'),
      value: 'new',
    },
    {
      label: t('price'),
      value: 'price',
    },
    {
      label: t('Name asc'),
      value: 'playerNameAsc',
    },
    {
      label: t('Name des'),
      value: 'playerNameDesc',
    },
  ];

  const handleSort = (e: any) => {
    if (e?.detail?.value) {
      setSortValue(e?.detail?.value);
    }
  };

  const handleLike = (nftMasterId?: string, teamId?: string) => {
    if (userProfileData?.id)
      likeCard({ nftMasterId, userId: userProfileData?.id, teamId: teamId! });
  };

  // Banner Image Collection
  useEffect(() => {
    let dataWithoutAuth = [],
      dataWithAuth = [];
    if (bannerData) {
      dataWithoutAuth = bannerData?.data?.map((d: any) => ({
        id: d?.id,
        imageUrl: d?.imageUrl,
        linkUrl: d?.linkUrl,
      }));
    }
    if (bannerDataAfterAuth) {
      dataWithAuth = bannerDataAfterAuth?.data?.map((d: any) => ({
        id: d?.id,
        imageUrl: d?.imageUrl,
        linkUrl: d?.linkUrl,
      }));
    }
    // Aggregate data from when user is with and without authentication
    const newData = [...dataWithoutAuth, ...dataWithAuth];
    setBanners(newData);
  }, [bannerData, bannerDataAfterAuth]);

  // Setup total pages of market list
  /* useEffect(() => {
    if (marketList?.data?.total) {
      setTotalPages(marketList?.data?.total?.toString() ?? '1');
      console.log('total page', totalPages);
    }
  }, [marketList]); */

  // Sort
  useEffect(() => {
    if (sortValue) {
      if (sortValue === 'new') {
        setSortDirection('desc');
        setSortField('createdAt');
      } else if (sortValue === 'price') {
        setSortDirection('asc');
        setSortField('price');
      } else if (sortValue === 'playerNameAsc') {
        setSortDirection('asc');
        setSortField('playerName');
      } else if (sortValue === 'playerNameDesc') {
        setSortDirection('desc');
        setSortField('playerName');
      }
      setCurrentPage(1);
    }
  }, [sortValue]);

  // Refetch to get new likes
  useIonViewDidEnter(() => {
    refetch();
  }, []);

  useIonViewDidEnter(() => {
    if (userProfileData?.id) refetchFavoritesData();
  }, [userProfileData?.id]);

  return (
    <Wrapper>
      <Loader isLoading={loadingBanners || loadingBannersAfterAuth} className="home-loader">
        <FilterModal
          show={showFilter}
          setShow={setShowFilter}
          title={`${t('Filter')}`}
          filters={filters}
          setFilters={setFilters}
          initialFilters={initialFilters}
          setIsMainSearch={setIsMainSearch}
          setCurrentPage={setCurrentPage}
        />
        <TitleComponent heading="NFT Market" subHeading="NFT Market" title color="#29C8D2" />
        <MarketWrapper>
          <ButtonWrapper>
            <Button>{t('Buy')}</Button>
            <Button disabled>{t('sell')}</Button>
          </ButtonWrapper>
        </MarketWrapper>
        <Loader isLoading={loadingBanners || loadingBannersAfterAuth}>
          <MarketBannerCarousel
            data={banners}
            noMarginBottom={!(authenticated && isFirstStepCompleted)}
          />
        </Loader>
        {authenticated && isFirstStepCompleted ? (
          favouriteData?.data?.utilityNfts?.length == 0 ? null : (
            <MarketCarouselSection
              heading="Favorite"
              subHeading="Favorite"
              detailBtnTitle="Favorite list"
              detailBtnRoute="/market/favourite-list"
              color="#29C8D2"
              data={favouriteData?.data?.utilityNfts?.map((b: any) => ({
                image: b?.nft?.templateFileUrl,
                teamName: teamDetails?.team?.teamName,
                teamLogo: teamDetails?.team?.primaryLogoImagePath,
                title: b?.nft?.cardName,
                price: `${b?.nft?.price?.toLocaleString()}円`,
                quantity: `${b?.nft?.currentSoldNumber?.toString()}/${b?.nft?.maxSalesNumber?.toString()}`,
                id: b?.nft?.id,
                // For favorites, we have to pass userId for like-system to work
                favorites: [{ userId: b?.userId }],
                teamId: b?.teamId,
              }))}
              loading={favouriteFetching}
              onLikeClick={handleLike}
              isLiking={isLiking}
              likingCardId={likingCardId}
              setLikingCardId={setLikingCardId}
              showLikeNumber={false}
            />
          )
        ) : null}
        {banners?.length !== 0 && (
          <MarketWrapper>
            <Divider className="market-divider" />
          </MarketWrapper>
        )}
        <SearchBar
          textToShow={filters.find((d: any) => d?.name === 'playerName')?.value ?? ''}
          setIsMainSearch={setIsMainSearch}
          setSearchText={setSearchText}
          setCurrentPage={setCurrentPage}
          searchText={searchText}
        />
        <ActionButtonWrapper>
          <Button size="small" className="btn" onClick={() => setShowFilter(true)}>
            {t('Filter')}
          </Button>
          <Dropdown onChange={handleSort} value={sortValue} options={options} />
        </ActionButtonWrapper>
        <MarketListLoader isLoading={loadingMarketList}>
          {marketList && marketList?.data?.utilityNfts?.length === 0 && (
            <Message>キーワードに該当するデータはありません</Message>
          )}
          <FavouriteListWrapper>
            {marketList?.data &&
              marketList?.data?.utilityNfts?.map((card) => {
                // after randomUtilityNfts has items field
                /*  let finalImage: string = '';
                if (card?.isRandomTemplate === true) {
                  finalImage = card?.randomUtilityNfts?.items![0].templateFileUrl ?? '';
                } else {
                  finalImage = card?.templateFileUrl ?? '';
                } */
                return (
                  <MarketCard
                    data={{
                      teamName: card?.team?.teamName,
                      teamLogo: card?.team?.primaryLogoImagePath,
                      // after randomUtilityNfts has items field
                      /*  image: finalImage, */
                      image: card?.templateFileUrl,
                      title: card?.cardName,
                      price: `${card?.price?.toLocaleString()}円`,
                      quantity: `${card?.currentSoldNumber?.toString()}/${card?.maxSalesNumber?.toString()}`,
                      id: card?.id,
                      likesNumber: card?.favorites?.items ? card?.favorites?.items?.length : 0,
                      favorites: card?.favorites?.items,
                      teamId: card?.team?.id,
                    }}
                    key={card?.id}
                    onLikeClick={handleLike}
                    isLiking={isLiking}
                    likingCardId={likingCardId}
                    setLikingCardId={setLikingCardId}
                  />
                );
              })}
          </FavouriteListWrapper>
        </MarketListLoader>
        <PaginationWrapper totalPage={totalPages}>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </PaginationWrapper>
      </Loader>
    </Wrapper>
  );
};

export default RestrictedRoute(NFTMarket);
