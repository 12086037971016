import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IonImg, IonLabel } from '@ionic/react';
import { useHistory } from 'react-router';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { ProgressBar } from '../../atoms/ProgressBar';
import { UserContext } from '../../../utils/UserContext';
import { Button, Loader, RadioSelect } from '../../../components';

const DISABLED_COLOR = '#4d4d4d';

export interface IMultipleVotingData {
  id: string;
  name?: string;
  image?: string;
  percentage?: number;
}

export interface IMutlipleVotingComponentProps {
  id: string;
  deadline: string;
  allowsMultiple?: boolean;
  data: IMultipleVotingData[];
  color?: string;
  showDistribution?: boolean;
  mutate?: any;
  isSuccess?: boolean;
  isMutateLoading?: boolean;
  hasVoted?: boolean;
  listParam?: string | null;
  showResult?: boolean;
}

const ImageWrapper = styled.div<any>`
  border-radius: 50px;
  margin-bottom: 25px;
  padding: ${(props) => (props.showDistribution ? '35px 35px 10px 35px' : '35px')};
  .image {
    display: flex;
    cursor: ${(props) =>
      props.showDistribution || props.hasVoted || props.listParam === 'endedVote' ? '' : 'pointer'};
  }
  .selected-image {
    border-radius: var(--border-radius);
    --box-shadow: var(--shadow-down);
  }
  .back-button {
    ion-button {
      margin-bottom: -10px;
    }
  }
`;

const ButtonWrapper = styled.div`
  ion-button {
    margin-bottom: 20px;
    font-size: 18px;
    color: #4d4d4d;
    font-weight: 700;
    text-align: left;
  }
  .selected-button {
    border-radius: var(--border-radius);
    --box-shadow: var(--shadow-down);
  }
`;

const Wrapper = styled.div`
  .deadline-wrapper {
    margin: 30px 0px;
    text-align: center;
    font-weight: 350;
    span {
      margin-right: 10px;
    }
  }
  .vote-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    ion-button {
      width: 150px;
      font-size: 12px;
      margin-bottom: 22px;
      :last-child {
        margin-bottom: 10px;
      }
    }
  }
  .back-button {
    ion-button {
      margin-bottom: 10px;
    }
  }

  .loader {
    ion-spinner {
      height: 12px;
      width: 12px;
      color: red;
    }
  }
`;

const MultipleVoteWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 0.9fr;
  margin-bottom: 20px;
  padding: 0 30px;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  ion-label {
    font-weight: bold;

    span {
      margin-right: 10px;
      color: #4d4d4d;
      font-size: 18px;
    }
  }
`;

const MultipleVoting: FC<IMutlipleVotingComponentProps> = ({
  allowsMultiple = false,
  data = [],
  deadline,
  color,
  showDistribution,
  id,
  mutate,
  isSuccess,
  isMutateLoading,
  hasVoted,
  listParam,
  showResult,
}) => {
  const [hasImage, setHasImage] = useState(true);
  const [selected, setSelected] = useState<any>([]);
  const [justVoted, setHasVoted] = useState<any>({ id: null, voted: false });

  const { userProfileData } = useContext(UserContext);

  const { t } = useTranslation();

  const { push } = useHistory();

  // useEffect(() => {
  //   if (showDistribution) {
  //     setHasVoted({ id: '', voted: true });
  //     let _votedIds: string[] = [];
  //     data.forEach((each) => {
  //       if (each?.voted) {
  //         _votedIds.push(each.id);
  //       }
  //       setSelected(_votedIds);
  //     });
  //   }
  // }, [showDistribution]);

  useEffect(() => {
    const hasImage = data.findIndex((item) => item.image !== undefined);
    if (hasImage > -1) {
      setHasImage(true);
    } else {
      setHasImage(false);
    }
  }, [data]);

  const vote = (id: string) => {
    if (listParam !== 'endedVote') {
      if (!justVoted.voted || !showDistribution) {
        setSelected([id]);
      }
    }
  };

  const withImage = useMemo(
    () => (
      <div>
        {data?.map((item) => (
          <div key={item?.id}>
            <ImageWrapper
              key={item?.id}
              showDistribution={showDistribution}
              hasVoted={hasVoted}
              listParam={listParam}
              className={`${
                showDistribution || hasVoted
                  ? 'neomorphs-shadow-out'
                  : `neomorphs-shadow-${selected[0] === item.id ? 'in' : 'out'}`
              }`}
            >
              <IonImg
                src={item?.image}
                className={`image ${selected[0] === item.id ? 'selected-image' : ''}`}
                onClick={() => vote(item.id)}
              />
              {showDistribution && (
                <div className="progress-bar-wrapper">
                  <ProgressBar
                    color={item?.percentage ? color : DISABLED_COLOR}
                    value={item?.percentage}
                  />
                </div>
              )}
            </ImageWrapper>
          </div>
        ))}
      </div>
    ),
    [selected, justVoted, showDistribution]
  );

  const withButton = useMemo(
    () => (
      <ButtonWrapper>
        {data?.map((item) => (
          <div key={item?.id}>
            <Button
              key={item?.id}
              color="black"
              size="large"
              className={`${selected[0] === item.id ? 'selected-button' : ''}`}
              onClick={() => vote(item.id)}
              disabled={showDistribution || hasVoted || listParam === 'endedVote'}
            >
              {item?.name}
            </Button>
            {showDistribution && (
              <ProgressBar
                color={item?.percentage ? color : DISABLED_COLOR}
                value={item?.percentage}
              />
            )}
          </div>
        ))}
      </ButtonWrapper>
    ),
    [selected, justVoted, showDistribution]
  );

  const handleMultipleSelect = (id: string) => {
    if (hasVoted || showDistribution || listParam === 'endedVote') {
      return '';
    }

    if (selected.some((item: string) => item === id)) {
      const _selected = selected.filter((item: string) => item !== id);
      setSelected(_selected);
    } else {
      setSelected([...selected, id]);
    }
  };

  const isSelected = (id: string) => {
    return selected?.some((item: string) => item === id);
  };

  const multipleNames = useMemo(() => {
    return (
      <div>
        {data?.map((item) => (
          <div key={item?.id}>
            <MultipleVoteWrapper key={item?.id}>
              <RadioSelect
                selected={isSelected(item.id)}
                handleSelect={() => {
                  handleMultipleSelect(item.id);
                }}
                color={color}
              />
              <div>
                <IonLabel>
                  {/* <span className="rank">#{item?.rank}</span> */}
                  {item.name}
                </IonLabel>
              </div>
            </MultipleVoteWrapper>
            {showDistribution && (
              <div>
                <ProgressBar
                  color={item?.percentage ? color : DISABLED_COLOR}
                  value={item?.percentage}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }, [selected, justVoted, showDistribution]);

  const handleUserVote = async () => {
    mutate &&
      (await mutate({
        id: id,
        userId: userProfileData?.id,
        userSelected: String(selected),
      }));
    localStorage.setItem('votedId', id);
  };

  useEffect(() => {
    if (isSuccess) {
      setHasVoted({ id: id, voted: true });
    }
  }, [isSuccess]);

  return (
    <Wrapper>
      {allowsMultiple ? multipleNames : hasImage ? withImage : withButton}
      <div className="deadline-wrapper">
        <p>
          <span>{t('Voting deadline:')}</span>
          <span>{deadline}</span>
        </p>
      </div>
      {showDistribution ? (
        <div className="vote-button-wrapper back-button">
          <Button color={color} onClick={() => push(`/voting-list?list=${listParam}`)}>
            {t('Back')}
          </Button>
        </div>
      ) : (
        <div className="vote-button-wrapper">
          {listParam === 'ongoingVote' && (
            <Button
              onClick={handleUserVote}
              disabled={
                (justVoted?.voted && justVoted.id === localStorage.getItem('votedId')) ||
                hasVoted ||
                !selected.length ||
                isMutateLoading
              }
              color={
                (justVoted?.voted && justVoted.id === localStorage.getItem('votedId')) ||
                !selected.length
                  ? '#ccc'
                  : color
              }
            >
              {isMutateLoading ? <Loader className="loader" isLoading={true} /> : t('Vote')}
            </Button>
          )}
          {showResult &&
            showResult === true &&
            (hasVoted || listParam === 'endedVote' ? (
              <Button
                className="vote-distribution-button"
                color={color}
                onClick={() => push(`voting-distribution?item=${id}&list=${listParam}`)}
              >
                {t('See vote distribution')}
              </Button>
            ) : (
              <Button
                disabled={justVoted.id !== localStorage.getItem('votedId') || !justVoted.voted}
                className="vote-distribution-button"
                color={justVoted.id !== localStorage.getItem('votedId') ? '#ccc' : color}
                onClick={() => push(`voting-distribution?item=${id}&list=${listParam}`)}
              >
                {t('See vote distribution')}
              </Button>
            ))}
        </div>
      )}
    </Wrapper>
  );
};

export { MultipleVoting };
