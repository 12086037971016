import React, { FC } from 'react';
import styled from 'styled-components';
import { MultipleVoting, AvatarCard, Divider } from '../../../components';
import { IMutlipleVotingComponentProps } from '../../molecules/MultipleVoting';

const Wrapper = styled.div`
  border-radius: 50px;
  padding: 15px;
  .divider {
    margin: 10px 10px 30px 10px !important;
    filter: blur(1px);
  }
  .avatar-wrapper {
    padding: 15px 23px;
  }
`;

interface IVotingPollProps extends IMutlipleVotingComponentProps {
  avatar: string;
  title: string;
  subtitle: string;
  mutate?: any;
  isSuccess?: boolean;
  isMutateLoading?: boolean;
  hasVoted?: boolean;
  listParam?: string | null;
  showResult?: boolean;
}

const VotingPoll: FC<IVotingPollProps> = ({
  data = [],
  deadline,
  id,
  color,
  allowsMultiple,
  avatar,
  title,
  subtitle,
  mutate,
  showDistribution,
  isSuccess,
  isMutateLoading,
  hasVoted,
  listParam,
  showResult,
}) => {
  return (
    <Wrapper className="neomorphs-shadow-in">
      <div className="avatar-wrapper">
        <AvatarCard title={title} subtitle={subtitle} avatar={avatar} color={color || 'black'} />
      </div>
      <Divider className="divider" />
      <MultipleVoting
        data={data}
        deadline={deadline}
        id={id}
        color={color}
        allowsMultiple={allowsMultiple}
        showDistribution={showDistribution}
        mutate={mutate}
        isSuccess={isSuccess}
        isMutateLoading={isMutateLoading}
        hasVoted={hasVoted}
        listParam={listParam}
        showResult={showResult}
      />
    </Wrapper>
  );
};

export { VotingPoll };
