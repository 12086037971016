import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { logoInstagram } from 'ionicons/icons';
import React, { useState, useEffect, useContext } from 'react';
import { IonImg, useIonViewDidEnter, IonModal, IonIcon } from '@ionic/react';

import { useToast } from '../hooks/useToast';
import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import { mobileContext } from '../utils/MobileContext';
import { Table } from '../components/molecules/Table';
import { useRecommendedNFTCard } from '../hooks/useRecommendedNFTCards';
import {
  fetchPurchasedCardData,
  purchasedCardDetailAPI,
  fetchMyCollectionDetailData,
  likeMarketCard,
} from '../services';
import {
  Button,
  CrossButton,
  IonPageComponent,
  Loader,
  MarketAccordion,
  MarketCard,
  MarketDetailPlayerCard,
  Pagination,
  TitleComponent,
  Toast,
} from '../components';
import { useTradingHistory } from '../hooks/useTradingHistory';
import { useLikingCardId } from '../hooks/useLikingCardId';

interface Props {
  isMyCard?: boolean;
}

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
`;

const PurchaseWrapper = styled.div`
  padding: 20px;

  .close-btn {
    display: flex;
    justify-content: flex-end;
  }

  .purchase-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 12px;

    .title {
      color: #29c8d2;
      font-size: 60px;
      font-weight: 900;
      line-height: 60px;
      text-align: center;
      margin-bottom: 25px;
    }

    .card-image {
      width: 90%;
      height: 50%;
      margin: 0 0 33px;
      filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.3));
    }

    p {
      color: #4d4d4d;
      margin-top: 0;
      font-size: 15px;
      font-weight: 400;
    }

    .player-name {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 11px;
      text-align: center;
    }

    .buy-button {
      width: 100%;
      margin: 25px 0 37px 0px;
    }

    .logo {
      width: 107px;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 0px;
  margin-bottom: 40px;
  .pb {
    padding-bottom: 13px;
  }
  .note {
    white-space: pre-line;
  }
`;

const ShadowWrapper = styled.div`
  box-shadow: var(--shadow-down);
  margin: 0px 0px 40px 0px;
  padding: 27px;
  border-radius: 3rem;
  p {
    margin: 0;
  }
  .title {
    color: #4d4d4d;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .subtitle {
    color: #4d4d4d;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 23px;
  }
`;

const Item = styled.div`
  line-height: 20px;
  span {
    color: #4d4d4d;
    font-size: 12px;
    font-weight: 400;
    line-height: 0px;
  }
  .first {
    min-width: 30%;
    display: inline-block;
    padding-right: 17px;
  }
  .second {
    min-width: 65%;
    display: inline-block;
  }
`;

const TableWrapper = styled.div`
  height: 303px;
  overflow: hidden;
  .table-title {
    position: sticky;
    top: 0;
    color: #4d4d4d;
    font-size: 20px;
    font-weight: 700;
    padding: 2px 0px 18px 15px;
  }
  .main-table {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 27px;
    ::-webkit-scrollbar {
      display: none;
    }
    .loader {
      height: 100px;
      margin-top: 50px;
    }
    .t-row {
      box-shadow: none;
      td {
        font-size: 12px;
        font-weight: 400;
        color: #4d4d4d;
      }
      @media (max-width: 395px) {
        td {
          padding: 14px 12px;
        }
      }
    }
  }

  .pagination {
    margin: 20px 0;
  }

  .no-data {
    height: 40px;
    text-align: center;
    margin: 30px 15px;
    p {
      font-size: 14px;
    }
  }
`;

const RecommendedWrapper = styled.div`
  position: relative;
  padding-top: 23px;
  min-height: 180px;
  .loader {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
  }
`;

const FavouriteListWrapper = styled.div`
  padding: 50px 6px 32px 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-row-gap: 17px;
  grid-column-gap: 5px;

  ion-card {
    width: 100%;
  }
`;

const IonModalComponent = styled(IonModal)<{ isMobile?: boolean }>`
  --width: ${(props) => (props.isMobile ? '100%' : '414px')};
  --height: 520px;
  --border-radius: 10px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.2);

  .ion-delegate-host {
    background-color: #efefef;
  }

  .modal-body {
    width: ${(props) => (props.isMobile ? '95%' : '335px')};
    height: auto;
    margin: 20px auto;
    border-radius: 50px;
    padding: 34px 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    .border {
      height: 1px;
      width: 90%;
      margin: 30px 0;
    }

    .social-row {
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon {
        padding: 10px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 17px;
      }

      span {
        width: 90px;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
`;

const MarketPurchaseCompletePage = ({ isMyCard = false }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { teamDetails, userProfileData } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showToast, setShowToast] = useToast(false);
  const [toastType, setToastType] = useState<'Error' | 'Success'>('Error');
  const [toastMessage, setToastMessage] = useState<any>('Something went wrong. Please try again.');
  const [currentPage, setCurrentPage] = useState(1);
  const [mainData, setMainData] = useState<any>(null);

  const { isMobile, ismobileWithIpad } = mobileContext();

  const COLS = [
    {
      Header: '日付',
      accessor: '日付',
      Filter: () => null,
    },
    {
      Header: '取引',
      accessor: '取引',
      Filter: () => null,
    },
    {
      Header: '価格',
      accessor: '価格',
      Filter: () => null,
    },
    {
      Header: '出品',
      accessor: '出品',
      Cell: (value: any) =>
        value.value !== null ? (
          <div
            style={{ cursor: 'copy' }}
            onClick={() => {
              navigator.clipboard.writeText(value.value);
              setToastType('Success');
              setShowToast(true);
              setToastMessage('出品者 wallet addressをクリップボードにコピーしました');
            }}
          >
            ...
          </div>
        ) : (
          <div>-</div>
        ),
      Filter: () => null,
    },
  ];

  // To get session Id or cardId of purchased card
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');
  const cardId = searchParams.get('card_id');
  const tokenId = searchParams.get('token_id');

  const { data: purchasedCardDataId, isLoading: purchasedCardLoading } = useQuery(
    ['fetchPurchasedCardData', sessionId],
    fetchPurchasedCardData,
    {
      enabled: !!sessionId,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (response) => {
        return response?.data?.nftCardId;
      },
    }
  );

  const { isLoading: utilityMasterDetailDataLoading } = useQuery(
    ['fetchutilityMasterDetailData', purchasedCardDataId],
    purchasedCardDetailAPI,
    {
      enabled: !!purchasedCardDataId,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      onSuccess: (data) => {
        setMainData(data?.data);
      },
    }
  );

  const { isLoading: myCollectionDataLoading } = useQuery<any>(
    ['fetchMyCollectionDetailData', cardId, tokenId],
    fetchMyCollectionDetailData,
    {
      enabled: !!(cardId && tokenId),
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      onSuccess: (data) => {
        setMainData(data?.data);
      },
    }
  );

  const {
    purchaseData: tradingData,
    isLoading: loadingTrading,
    isFetching: fetchingTrading,
    total: tradingTotalPage,
  } = useTradingHistory(
    mainData?.masterData?.id ? mainData?.masterData?.id : cardId,
    mainData?.masterData?.id || cardId ? true : false,
    currentPage
  );

  const { data: recommendedData, isLoading: recommendedLoading } = useRecommendedNFTCard(
    teamDetails?.team?.id,
    userProfileData?.id,
    teamDetails?.team?.id ? true : false
  );

  const shareHandler = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // STATIC IMAGE

  const handleFbShare = () => {
    // replace appId & imageUrl during API integration
    const messengerUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      mainData?.fileUrl
    )}`;

    window.open(messengerUrl, '_blank');
  };

  const handleTweetShare = () => {
    const tweetUrl = `https://twitter.com/intent/tweet?url=${mainData?.fileUrl}`;

    window.open(tweetUrl, '_blank');
  };

  useIonViewDidEnter(() => {
    document.title = '購入済 - Fanique';
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.querySelector('ion-content')?.classList.add('no-scroll');
    } else {
      document.querySelector('ion-content')?.classList.remove('no-scroll');
    }
  }, [isModalOpen]);

  const handleDiscordShare = async () => {
    const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_URL;
    const imgUrl = mainData?.fileUrl;
    const avatarUrl = `${teamDetails.team.teamImagePath}`;
    const serverId = process.env.REACT_APP_DISCORD_SERVER_ID;
    const channelId = process.env.REACT_APP_DISCORD_CHANNEL_ID;
    try {
      const response = await axios.post(webhookUrl as string, {
        username: userProfileData?.nickname || 'Fanique Bot',
        content: 'My New Card',
        avatar_url: avatarUrl || '',
        embeds: [
          {
            image: {
              url: imgUrl,
            },
          },
        ],
      });

      if (response) {
        window.open(`https://discord.com/channels/${serverId}/${channelId}`, '_blank');
      }
    } catch (_) {
      setToastMessage('Something went wrong. Please try again.');
      setToastType('Error');
      setShowToast(true);
    }
  };

  const openWebShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'WebShare API Demo',
        url: mainData?.templateFileUrl,
      });
    } else {
      setToastMessage('Not supported yet!');
      setShowToast(true);
    }
  };

  // Like NFT Card
  const { likingCardId, setLikingCardId } = useLikingCardId();
  const { mutate: likeCard, isLoading: isLiking } = useMutation(likeMarketCard, {
    onSuccess: () => {
      setLikingCardId('');
    },
  });

  const handleLike = (nftMasterId?: string, teamId?: string) => {
    if (setLikingCardId) setLikingCardId(nftMasterId);
    if (userProfileData?.id)
      likeCard({ nftMasterId, userId: userProfileData?.id, teamId: teamId! });
  };
  return (
    <IonPageComponent>
      {showToast && <Toast message={t(toastMessage)} type={toastType} />}
      <IonModalComponent
        isOpen={isModalOpen}
        onDidDismiss={closeModal}
        initialBreakpoint={ismobileWithIpad ? 1 : 0.75}
        breakpoints={[0, 0.25, 0.5, 0.75, 1]}
        handleBehavior="cycle"
        isMobile={isMobile}
      >
        <div className="modal-body neomorphs-shadow-in">
          <div className="social-row" onClick={handleFbShare}>
            <IonIcon src="/assets/icon/facebook-logo.svg" className="icon neomorphs-shadow-out" />
            <span>Facebook</span>
          </div>
          {ismobileWithIpad && (
            <>
              <div className="border neomorphs-shadow-in"></div>
              <div className="social-row" onClick={openWebShare}>
                <IonIcon src={logoInstagram} className="icon neomorphs-shadow-out" />
                <span>Instagram</span>
              </div>
            </>
          )}
          <div className="border neomorphs-shadow-in"></div>
          <div className="social-row" onClick={handleTweetShare}>
            <IonIcon src="/assets/icon/twitter-logo.svg" className="icon neomorphs-shadow-out" />
            <span>Twitter</span>
          </div>
          <div className="border neomorphs-shadow-in"></div>
          <div className="social-row" onClick={() => handleDiscordShare()}>
            <IonIcon src="/assets/icon/discord-logo.svg" className="icon neomorphs-shadow-out" />
            <span>Discord</span>
          </div>
        </div>
      </IonModalComponent>
      <PurchaseWrapper>
        <StyledLoader
          isLoading={
            purchasedCardLoading ||
            utilityMasterDetailDataLoading ||
            myCollectionDataLoading ||
            !mainData
          }
        >
          <div className="close-btn">
            <CrossButton
              onClick={() =>
                isMyCard
                  ? history.push('/my-page')
                  : history.push(`/market?session_id=${sessionId}`)
              }
            />
          </div>
          <div className="purchase-body">
            <span className="title">I’m the Owner</span>
            <IonImg className="card-image" src={mainData?.fileUrl} />
            <p className="player-name">{mainData?.masterData?.cardName}</p>
            <p className="total">
              {t('Total')} : {isMyCard ? tokenId : `${mainData?.masterData?.currentSoldNumber}`}/
              {mainData?.masterData?.maxSalesNumber}
            </p>

            <Button size="large" className="buy-button" onClick={shareHandler}>
              {t('Share')}
            </Button>
          </div>

          <ContentWrapper>
            <MarketDetailPlayerCard
              data={mainData?.masterData}
              hasBuyBtn={false}
              noMarginTop
              hasLikesBtn={false}
            />
          </ContentWrapper>

          <ShadowWrapper>
            <p className="title">{t('Generate information')}</p>
            <p className="subtitle">Fanique NFT</p>
            <Item>
              <span className="first">出品者</span>
              <span className="second">{mainData?.masterData?.team?.teamName}</span>
            </Item>
            <Item>
              <span className="first">Token ID</span>
              <span className="second">{mainData?.tokenId ?? 0}</span>
            </Item>
          </ShadowWrapper>

          <ShadowWrapper>
            <TableWrapper>
              <p className="table-title">{t('Transaction History')}</p>
              <div className="main-table">
                <Loader className="loader" isLoading={loadingTrading || fetchingTrading}>
                  {tradingData.length ? (
                    <Table columns={COLS} data={tradingData} />
                  ) : (
                    <div className="no-data">
                      <p>{t('No Data Available')}</p>
                    </div>
                  )}
                  <div className="pagination">
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={tradingTotalPage}
                    />
                  </div>
                </Loader>
              </div>
            </TableWrapper>
          </ShadowWrapper>

          {/* <ContentWrapper> MIGHT NEED LATER
            <MarketAccordion title={t('Details')}>
              <div className="pb">
                <Item>
                  <span className="first">提供者</span>
                  <span className="second">{data?.details?.provider}</span>
                </Item>
                <Item>
                  <span className="first">マーケット ID</span>
                  <span className="second">{data?.details?.marketId}</span>
                </Item>
              </div>
            </MarketAccordion>
          </ContentWrapper> */}

          <ContentWrapper>
            <MarketAccordion title={t('Careful Notes')}>
              <p className="note">{mainData?.masterData?.notes?.replaceAll('\\n', '\n')}</p>
            </MarketAccordion>
          </ContentWrapper>

          <RecommendedWrapper>
            <TitleComponent heading="Recommend" subHeading={t('Recommend')} title color="#29C8D2" />
            <Loader isLoading={recommendedLoading} className="loader">
              <FavouriteListWrapper>
                {recommendedData &&
                  recommendedData?.recommendItems?.map((card: any) => {
                    return (
                      <MarketCard
                        data={{
                          teamName: card?.team?.teamName,
                          teamLogo: card?.team?.primaryLogoImagePath,
                          image: card?.templateFileUrl,
                          title: card?.cardName,
                          price: `${card?.price?.toLocaleString()}円`,
                          quantity: `${card?.currentSoldNumber?.toString()}/${card?.maxSalesNumber?.toString()}`,
                          id: card?.id,
                          likesNumber: card?.favorites ? card?.favorites : 0,
                          favorites: recommendedData?.userFaviritesItems ?? [],
                          teamId: card?.team?.id,
                        }}
                        key={card?.id}
                        onLikeClick={handleLike}
                        isLiking={isLiking}
                        likingCardId={likingCardId}
                        setLikingCardId={setLikingCardId}
                        isRecommended
                      />
                    );
                  })}
              </FavouriteListWrapper>
            </Loader>
          </RecommendedWrapper>
        </StyledLoader>
      </PurchaseWrapper>
    </IonPageComponent>
  );
};

export default PrivateRoute(MarketPurchaseCompletePage) as any;
