import { t } from 'i18next';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';

import { useToast } from '../hooks/useToast';
import { purchaseMembership } from '../services';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { TitleComponent, Button, ProfileData, Toast } from '../components';
import RestrictedRoute from '../withRestrictedRoute';

const ContentWrapper = styled.div`
  padding: 0 20px;
  hr {
    margin: 0;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 25px;
  .button-section {
    padding-top: 1rem;
    text-align: center;
    > ion-button {
      margin-bottom: 1.7rem;
    }
    .btn {
      width: 140px;
    }
  }
  .home-button {
    padding-bottom: 25px;
  }
`;

const EditButtonWrapper = styled.div`
  padding: 40px 0px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  ion-button {
    width: 140px;
  }
  .personal-info {
    width: 150px;
  }
`;

const PersonalInformationConfirmationPage: React.FC = () => {
  const { push, replace } = useHistory();
  const [userData, setUserData] = useState<any>();
  const { userProfileData, teamDetails } = useContext(UserContext);
  const [showToast, setShowToast] = useToast(false);
  const [error, setError] = useToast('');
  const location: any = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const statusId = searchParams.get('status');
  const teamId = searchParams.get('teamId');
  const cardName = searchParams.get('card');
  const price = searchParams.get('price');

  const { mutate, isLoading } = useMutation(purchaseMembership, {
    onSuccess: ({ data }) => {
      window.open(data?.url, '_self');
    },
    onError: (err: any) => {
      if (err?.response?.data?.message === 'Only one membership can be possessed per team.') {
        setError(err?.response?.data?.message);
        setShowToast(true);
      } else {
        replace('/404');
      }
    },
  });

  const checkoutHandler = () => {
    if (cardName === 'DIAMOND') {
      push({
        pathname: `/inquiry`,
        search: `?card=${cardName}`,
      });
    } else {
      mutate({
        statusId: statusId,
        userId: userProfileData?.id,
      });
    }
  };

  useEffect(() => {
    setUserData({
      name_mei: userProfileData?.name_mei,
      name_sei: userProfileData?.name_sei,
      iconUrl: userProfileData?.iconUrl,
      furigana_mei: userProfileData?.furigana_mei,
      furigana_sei: userProfileData?.furigana_sei,
      email: userProfileData?.email,
      birthday: userProfileData?.birthday,
      postcode: userProfileData?.postcode,
      mailPostcode: userProfileData?.mailPostcode,
      address: userProfileData?.address,
      address1: userProfileData?.address1,
      mailAddress: userProfileData?.mailAddress,
      mailAddress1: userProfileData?.mailAddress1,
      phone: userProfileData?.phone,
      nickname: userProfileData?.nickname,
      gender: userProfileData?.gender,
      occupation: userProfileData?.occupation,
      termsAndCondition: '同意する',
    });
  }, [userProfileData]);

  return (
    <Wrapper>
      {showToast && <Toast message={t(error)} type="Error" />}
      <ContentWrapper>
        <TitleComponent
          title
          heading="Personal Data"
          subHeading="Personal information"
          color={teamDetails?.team?.teamColor}
        />
        <ProfileData fieldData={userData} color={teamDetails?.team?.teamColor}>
          <ButtonWrapper>
            <div className="home-button">
              <Button
                size="large"
                disabled={isLoading}
                color={teamDetails?.team?.teamColor}
                onClick={() => checkoutHandler()}
              >
                {cardName !== 'DIAMOND' ? t('Go to payment') : t('Diamond Membership Consult')}
              </Button>
            </div>
          </ButtonWrapper>
        </ProfileData>
        <EditButtonWrapper>
          <Button
            className="personal-info"
            size="small"
            color={teamDetails?.team?.teamColor}
            onClick={() =>
              push({
                pathname: `/personal-information`,
                search: `?teamId=${teamId}&status=${statusId}&card=${cardName}&price=${price}`,
              })
            }
          >
            {t('Correct personal info')}
          </Button>
        </EditButtonWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default RestrictedRoute(PersonalInformationConfirmationPage);
