import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { heart, heartOutline } from 'ionicons/icons';

import { Button } from '../../atoms';
import { convertToNDigits } from '../../../utils';
import { likeMarketCard } from '../../../services';
import { AuthContext } from '../../../utils/AuthContext';
import { UserContext } from '../../../utils/UserContext';
import { useLikingCardId } from '../../../hooks/useLikingCardId';
import { useMembershipExpiration } from '../../../hooks/useMembershipExpiration';

interface Props {
  hasBuyBtn?: boolean;
  noMarginTop?: boolean;
  hasLikesBtn?: boolean;
  data?: any;
  setToastType?: React.Dispatch<React.SetStateAction<'Error' | 'Success'>>;
  setShowToast?: React.Dispatch<React.SetStateAction<boolean>>;
  setToastMessage?: React.Dispatch<React.SetStateAction<string>>;
}

const Wrapper = styled.div<any>`
  position: relative;
  overflow: hidden;
  border-radius: 3rem;
  box-shadow: var(--shadow-down);
  padding: 24px;
  margin: ${(props: Props) => (props?.noMarginTop ? '0px 0px 46px 0px' : '46px 0px')};

  .sold-out-wrapper {
    position: absolute;
    background-color: #bf1f3e;
    width: 200px;
    text-align: center;
    transform: rotate(50deg);
    right: -60px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), -1px 1px 3px 0px rgba(0, 0, 0, 0.1),
      -5px 3px 6px 0px rgba(0, 0, 0, 0.09), -12px 7px 8px 0px rgba(0, 0, 0, 0.05),
      -21px 13px 10px 0px rgba(0, 0, 0, 0.01), -33px 20px 11px 0px rgba(0, 0, 0, 0);

    p {
      color: #ffffff;
      padding: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }
  }

  p {
    margin: 0;
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .logo-img {
      width: 55px;
      height: 51px;
      margin-right: 14px;
    }
    .team-name {
      color: #4d4d4d;
      font-size: 13px;
      font-weight: 400;
    }
  }
  .player-name {
    color: #4d4d4d;
    font-size: 20px;
    font-weight: 700;
    margin: ${(props: any) =>
      props?.hasLikesBtn && props?.authenticated && props?.isFirstStepCompleted
        ? '0px 0px 12px 5px'
        : '0px 0px 40px 5px'};
  }
  .small-button {
    width: 68px;
    font-size: 8px;
    margin: 0px;
    color: #4d4d4d;
    font-weight: 400;
    margin-bottom: 14px;

    ion-icon {
      font-size: 12px;
      color: #29c8d2;
    }

    ::part(native) {
      padding: 5px 0px !important;
    }
  }
  .description {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 5px;
    .details {
      color: #4d4d4d;
      .price {
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 4px;
      }
      .price-detail {
        font-size: 11px;
        font-weight: 400;
        margin-bottom: 13px;
      }
      .quantity {
        font-size: 12px;
        font-weight: 400;
        margin-top: 13px;
      }
      .info-text {
        font-size: 12px;
        width: 90%;
      }
    }
    .button-wrapper {
      height: fit-content;
      bottom: 48px;
      position: relative;
      ion-button {
        border-radius: 50%;
        width: 66px;
        height: 66px;
        font-size: 17px;
        font-weight: 700;
      }
    }
  }
  .note {
    white-space: pre-line;
    font-size: 12px;
  }
`;

export const MarketDetailPlayerCard: React.FC<Props> = ({
  hasBuyBtn = true,
  noMarginTop = false,
  hasLikesBtn = true,
  data,
  setToastType,
  setShowToast,
  setToastMessage,
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { isExpired } = useMembershipExpiration();
  const { authenticated, login } = useContext(AuthContext);
  const { isFirstStepCompleted, userProfileData } = useContext(UserContext);

  const handleCardPurchase = async () => {
    if (!authenticated) {
      sessionStorage.setItem(
        'redirect_to',
        `${window.location.origin}/market/purchase-confirmation/${data?.id}`
      );
      await login();
    } else {
      if (isExpired) {
        setToastType?.('Error');
        setToastMessage?.(
          'Your membership card is expired, Please renew before to purchase this card.'
        );
        setShowToast?.(true);
      } else {
        push({
          pathname: `/market/purchase-confirmation/${data?.id}`,
        });
        sessionStorage.removeItem('redirect_to');
      }
    }
  };

  // Like Market Card API
  const { likingCardId, setLikingCardId } = useLikingCardId();
  const { mutate: likeCard, isLoading: isLiking } = useMutation(likeMarketCard, {
    onSuccess: () => {
      setLikingCardId('');
    },
  });

  // Asynchronous Like Count
  const foundUser = data?.favorites?.items?.find((d: any) => d?.userId === userProfileData?.id);
  const [likeCount, setLikeCount] = useState<number>(data?.favorites?.items?.length ?? 0);
  const [liked, setLiked] = useState<boolean>(foundUser ? true : false);

  const handleLike = (nftMasterId?: string, teamId?: string) => {
    if (setLikingCardId) setLikingCardId(nftMasterId);
    // Call Like API
    if (userProfileData?.id)
      likeCard({ nftMasterId, userId: userProfileData?.id, teamId: teamId! });

    // Statically Update like count in UI (We don't have to wait for like api to resolve)
    setLiked(!liked);
    setLikeCount(liked ? likeCount - 1 : likeCount + 1);
  };

  const checkDisabled = (likingCardId?: string, cardId?: string, isLiking?: boolean) => {
    return likingCardId === cardId && isLiking;
  };
  return (
    <Wrapper
      noMarginTop={noMarginTop}
      hasLikesBtn={hasLikesBtn}
      authenticated={authenticated}
      isFirstStepCompleted={isFirstStepCompleted}
    >
      {/* Soldout button design */}
      {data?.currentSoldNumber === data?.maxSalesNumber && (
        <div className="sold-out-wrapper">
          <p>Sold Out</p>
        </div>
      )}

      <div className="header">
        <IonImg className="logo-img" src={data?.team?.primaryLogoImagePath} />
        <p className="team-name">{t(data?.team?.teamName ?? '')}</p>
      </div>
      <p className="player-name">{data?.cardName}</p>
      {hasLikesBtn && authenticated && isFirstStepCompleted && (
        <Button
          className="small-button"
          size="small"
          onClick={() => handleLike(data?.id, data?.team?.id)}
          icon={liked ? heart : heartOutline}
          disabled={checkDisabled(likingCardId, data?.id, isLiking)}
        >
          {data?.favorites?.items?.length || likeCount ? convertToNDigits(likeCount, 4) : '0000'}
        </Button>
      )}
      <div className="description">
        <div className="details">
          {hasBuyBtn && <p className="price">{data?.price}円</p>}
          <p className="price-detail">{data?.tag}</p>
          <p className="info-text">限定60枚</p>
          <p className="info-text">※おひとり様複数回の購入も可能です。</p>
          <p className="info-text">※4種類のカラー背景から1種類ランダムで生成されます。</p>
          <p className="quantity">
            枚数 : {data?.currentSoldNumber}/{data?.maxSalesNumber}
          </p>
        </div>
        {hasBuyBtn && (
          <div className="button-wrapper">
            {data?.currentSoldNumber === data?.maxSalesNumber ? (
              <Button expand="block" size="small" disabled>
                {t('Buy')}
              </Button>
            ) : (
              <Button expand="block" size="small" onClick={handleCardPurchase}>
                {t('Buy')}
              </Button>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};
