import React from 'react';
import { t } from 'i18next';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';

import { Divider } from '../../atoms';

const FormWrapper = styled.div<any>`
  border-radius: 50px;
  padding: 0 13px;
  padding-bottom: 0px;
  padding-top: 20px;
  margin-top: 30px;

  .each-row {
    display: flex;
    padding: 0 15px;
    margin: 0px;
    .align-self-center {
      align-self: center;
    }
    .w-100 {
      width: 100% !important;
    }
    .avatar {
      width: 77px;
      height: 77px;
      margin-bottom: 5px;
      ::part(image) {
        border-radius: 50%;
      }
    }
    .colored {
      color: ${({ color }) => color};
    }
    .bold {
      font-weight: 700 !important;
    }
    .title {
      width: 90px;
      padding-right: 10px;
      font-weight: 350;
      font-size: 13px;
      line-height: 19px;
    }
    .value {
      width: 75%;
      font-weight: 700;
      font-size: 13px;
      line-height: 19px;
      span {
        font-size: 13px;
        margin-right: 10px;
      }
    }

    @media (max-width: 385px) {
      .value {
        width: 150px;
        white-space: pre-line;
      }
    }
  }
  .delivery {
    padding-left: 0px;
    padding-right: 0px;
    p {
      margin: 0px 0px 15px;
    }
  }
  .block {
    display: block;
  }
  .discord-username {
    font-size: 10px !important;
    line-height: 13px !important;
  }
`;

interface Data {
  name_mei: string;
  name_sei: string;
  iconUrl: string;
  furigana_mei: string;
  furigana_sei: string;
  email: string;
  birthday: string;
  postcode: string;
  mailPostcode: string;
  address?: string;
  address1?: string;
  mailAddress?: string;
  mailAddress1?: string;
  phone: string;
  nickname: string;
  gender: string;
  occupation: string;
  termsAndCondition: string;
}

interface Props {
  fieldData: Data;
  children: any;
  color?: string;
}

const ProfileData: React.FC<Props> = ({ fieldData, children, color }) => {
  return (
    <>
      <FormWrapper className="neomorphs-shadow-in" color={color}>
        <div className="each-row">
          <p className="title align-self-center">{t('Image')}</p>
          {/* We used 'Date.now()' to remove image caching. */}
          <IonImg
            src={
              fieldData?.iconUrl
                ? `${fieldData?.iconUrl}?${Date.now()}`
                : './assets/avatar/user-avatar.png'
            }
            className="avatar"
          />
        </div>
        <div className="each-row">
          <p className="title">{t('Name')}</p>
          <p className="value">
            <span>
              {fieldData?.name_sei} {fieldData?.name_mei}
            </span>
          </p>
        </div>
        <Divider />
        <div className="each-row">
          <p className="title">{t('Furigana')}</p>
          <p className="value">
            <span translate="no">
              {fieldData?.furigana_sei} {fieldData?.furigana_mei}
            </span>
          </p>
        </div>
        <Divider />
        <div className="each-row">
          <p className="title">{t('Email')}</p>
          <p className="value">{fieldData?.email}</p>
        </div>
        <Divider />
        <div className="each-row">
          <p className="title" translate="no">
            {t('DOB')}
          </p>
          <p className="value">{fieldData?.birthday}</p>
        </div>
        <Divider />
        <div className="each-row">
          <p className="title">{t('Po. box')}</p>
          <p className="value">{fieldData?.postcode}</p>
        </div>
        <Divider />
        <div className="each-row">
          <p className="title">{t('Address')}</p>
          <p className="value">
            <span className="value">
              {fieldData?.address}
              {fieldData?.address1}
            </span>
          </p>
        </div>
        <Divider />
        <div className="each-row block">
          <p className="title w-100" translate="no">
            {t('If the delivery address is different')}
          </p>
          <div className="each-row delivery">
            <p className="title">{t('Po. box')}</p>
            <p className="value">{fieldData?.mailPostcode}</p>
          </div>
          <div className="each-row delivery">
            <p className="title">{t('Address')}</p>
            <p className="value">
              <span>
                {fieldData?.mailAddress}
                {fieldData?.mailAddress1}
              </span>
            </p>
          </div>
        </div>
        <Divider />
        <div className="each-row">
          <p className="title">{t('Phone')}</p>
          <p className="value">{fieldData?.phone}</p>
        </div>
        <Divider />
        <div className="each-row">
          <p className="title discord-username" translate="no">
            Discord
            <br />
            ユーザーネーム
          </p>
          <p className="value" translate="no">
            {fieldData?.nickname}
          </p>
        </div>
        <Divider />
        <div className="each-row">
          <p className="title" translate="no">
            {t('Gender')}
          </p>
          <p className="value">{fieldData?.gender}</p>
        </div>
        <Divider />
        <div className="each-row">
          <p className="title">{t('Occupation')}</p>
          <p className="value">{fieldData?.occupation}</p>
        </div>
        <Divider />

        <Divider />
        <div className="each-row">
          <p className="title colored bold">{t('Terms&conditions')}</p>
          <p className="value">{fieldData?.termsAndCondition}</p>
        </div>
        <Divider />
        {children}
      </FormWrapper>
    </>
  );
};

export { ProfileData };
