import { IonImg, IonText } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';

interface IAvatarCardProps {
  avatar: string;
  title: string;
  subtitle: string;
  color: string;
}

const MainWrapper = styled.div<any>`
  display: flex;
  .text-wrapper {
    padding: 7px 15px;
    .title {
      font-size: 18px;
      color: ${(props) => props.color};
      font-weight: 700;
      line-height: 26px;
    }
    .subtitle {
      display: block;
      padding-top: 4px;
      font-size: 15px;
      line-height: 22px;
      color: black;
      font-weight: 350;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 40px;
  min-width: 40px;
  height: 40px;
  padding: 2px;
  border-radius: 50%;
  div {
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    .avatar {
      object-fit: cover;
    }
  }
`;

const AvatarCard: React.FC<IAvatarCardProps> = ({ avatar, title, subtitle, color }) => {
  return (
    <MainWrapper color={color}>
      <ImageWrapper className="neomorphs-shadow-in">
        <div>
          <IonImg src={avatar} className="avatar" />
        </div>
      </ImageWrapper>
      <div className="text-wrapper">
        <div>
          <IonText className="title">{title}</IonText>
        </div>
        <div>
          <IonText className="subtitle">{subtitle}</IonText>
        </div>
      </div>
    </MainWrapper>
  );
};

export { AvatarCard };
