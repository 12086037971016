import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { caretDownOutline, caretUpOutline } from 'ionicons/icons';
import { IonIcon, IonImg, IonItem, IonLabel, IonList } from '@ionic/react';

import { Button, Loader } from '../../atoms';
import { AuthContext } from '../../../utils/AuthContext';
import { mobileContext } from '../../../utils/MobileContext';
import { useMembershipExpiration } from '../../../hooks/useMembershipExpiration';

type TMenu = {
  link: string;
  text: string[] | string;
  options?: {
    link: string;
    text: string[] | string;
  }[];
};

const Menu = styled.div<any>`
  position: fixed;
  z-index: 998;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none !important;
  }
  top: -100vh;
  width: 100%;
  max-width: 414px;
  height: ${(props) => (props.isMobile ? 'calc(100vh - 115px)' : 'calc(100vh - 75px)')};
  padding: 8px 35px;
  padding-bottom: ${(props) => props.isMobile && '50px'};
  background: ${({ color }) => color || '#29C8D2'};
  transition: transform 0.6s ease-in-out;
  @media (max-width: 480px) {
    max-width: 100%;
  }
  @media (min-width: 450px) {
    max-width: ${(props) => props.isMobile && '100%'};
  }
  &.show {
    padding-top: ${(props) => !props.isAuthenticated && '2em'};
    transform: translateY(calc(100vh + 75px));
  }
  .logo-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 10px;
    ion-img {
      width: 202px;
      height: 41px;
    }
  }
  ion-list {
    ion-item {
      --border-style: none;
      --inner-padding-end: 0;
      cursor: pointer;
      position: relative;
      ::before,
      ::after {
        position: absolute;
        bottom: 0;
        width: 100%;
        content: '';
        border: ${({ color }) => `0.1px solid ${color || '#29C8D2'}`};
        filter: brightness(0.8) blur(1px);
      }

      ::before {
        bottom: 2px;
        filter: brightness(1.1) blur(1px);
      }
      ::part(native) {
        padding: 0;
      }

      .secondary-items {
        padding-bottom: 10px;
      }
    }
    ion-label {
      --color: #fff !important;
      font-size: 15px !important;
      font-weight: 700;
      margin-top: 11px;
      margin-bottom: 15px;
      text-overflow: initial !important;
      white-space: normal !important;
      min-width: auto;
      align-self: flex-start;
      :hover {
        color: #a3a3a3 !important;
      }
      :active {
        color: #a3a3a3 !important;
      }
      :nth-child(2) {
        font-size: 11px !important;
        font-weight: 400;
        text-align: right;
        min-width: 30%;
      }
      ion-icon {
        position: absolute;
        top: 16px;
        margin-left: 6px;
      }
      .label-options {
        display: none;
        margin-left: 3px;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 6px;
        &.show-options {
          display: flex;
        }
        ul {
          margin: 0px !important;
          padding-left: 15px;
        }
        li {
          font-size: 11px;
          font-weight: 400;
          margin: 11px 0 15px 0;
          color: #fff !important;
          :hover {
            color: #a3a3a3 !important;
          }
          :active {
            color: #a3a3a3 !important;
          }
        }
      }
    }

    .label {
      white-space: pre !important;
    }
    &[lines='none'] {
      ion-item {
        ::part(native) {
          min-height: 40px;
        }
        ::before,
        ::after {
          display: none;
        }
        ion-label {
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 0;
        }
      }
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin: 55px 0 0px;
    padding-bottom: 50px;
    ion-button {
      width: 140px;
      color: ${({ color }) => color};
    }
  }
  .disabled {
    color: #a3a3a3 !important;
    cursor: not-allowed;
  }

  .logout {
    color: white;
    font-size: 15px;
    cursor: pointer;

    :hover {
      color: #a3a3a3;
    }
  }
`;

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isFetching?: boolean;
  primaryMenuItems?: TMenu[];
  secondaryMenuItems?: TMenu[] | any[];
  logoPath?: string;
  closeMenu?: () => void;
  color?: string;
  isAuthenticated: boolean;
  teamListLoading: boolean;
}

const OffCanvasHeader = ({
  show,
  setShow,
  isFetching = true,
  primaryMenuItems,
  secondaryMenuItems,
  logoPath,
  closeMenu,
  color,
  isAuthenticated,
  teamListLoading,
}: Props) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { isMobile } = mobileContext();
  const [toggleOptions, setToggleOptions] = useState<boolean>(false);
  const [disabledItems, setDisabledIetms] = useState<string[]>([]);

  const { isExpired } = useMembershipExpiration();
  const { authenticated, logout } = useContext(AuthContext);

  const showOptions = () => {
    setToggleOptions(!toggleOptions);
  };

  const changeRoute = (item: any) => {
    if (disabledItems.includes(item.link)) {
      return;
    }
    if (item.link.includes('https')) {
      window.open(item.link, '_blank');
    } else if (item.link === '/chat') {
      window.open('https://discord.gg/jqAhWvjYYT', '_blank'); //discord URL for design env only
    } else {
      push(item.link);
    }
    setTimeout(() => {
      setShow(false);
    }, 600);
  };

  // Render Items
  const renderMenuItems = (menuType: string, menuItems: TMenu[]) => (
    <IonList lines={menuType === 'primary' ? 'full' : 'none'}>
      {menuItems.map((item, index) => (
        <IonItem onClick={() => (item.options ? showOptions() : changeRoute(item))} key={index}>
          {Array.isArray(item.text) ? (
            <>
              <IonLabel className={`${disabledItems.includes(item.link) ? 'disabled' : ''}`}>
                {item.text[0]}
                {item.options && (
                  <IonIcon src={toggleOptions ? caretUpOutline : caretDownOutline} />
                )}
                {item.options && (
                  <Loader isLoading={teamListLoading}>
                    <div className={`label-options ${toggleOptions ? 'show-options' : ''}`}>
                      <ul>
                        {item.options.map((option, idx) => (
                          <li key={idx} onClick={() => changeRoute(option)}>
                            {option.text}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Loader>
                )}
              </IonLabel>
              <IonLabel className={`label ${disabledItems.includes(item.link) ? 'disabled' : ''}`}>
                {item.text[1]}
              </IonLabel>
            </>
          ) : (
            <IonLabel className="secondary-items">{item.text}</IonLabel>
          )}
        </IonItem>
      ))}
    </IonList>
  );

  useEffect(() => {
    if (isExpired) {
      setDisabledIetms(['/home', '/voting-list?list=ongoingVote']);
    }
  }, [isExpired]);

  return (
    <Menu
      className={`off-canvas-menu ${show ? `show` : ``}`}
      isMobile={isMobile}
      color={color}
      authenticated={isAuthenticated}
    >
      {logoPath && (
        <div className="logo-wrapper">
          <IonImg src={logoPath} />
        </div>
      )}
      <Loader isLoading={isFetching}>
        {primaryMenuItems && renderMenuItems('primary', primaryMenuItems)}
        {secondaryMenuItems && renderMenuItems('secondary', secondaryMenuItems)}
        {secondaryMenuItems?.length !== 0 && authenticated && (
          <span
            className="logout"
            onClick={async () => {
              await logout();
              localStorage.clear();
            }}
          >
            {t('Logout')}
          </span>
        )}
      </Loader>
      <div className="button-wrapper">
        <Button size="small" expand="block" onClick={closeMenu}>
          {t('Close')}
        </Button>
      </div>
    </Menu>
  );
};

export default OffCanvasHeader;
