import styled from 'styled-components';
import PrivateRoute from '../withPrivateRoute';
import { useIonViewWillEnter } from '@ionic/react';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import React, { useContext, useEffect, useState } from 'react';
import {
  TeamDescription,
  MyCollections,
  UsernameCard,
  TeamList,
  Loader,
  Pagination,
} from '../components';
import { useQuery } from 'react-query';
import { fetchNFTOwnedUser } from '../services';

interface Props {
  color?: string;
}

const Container = styled.div`
  .loader {
    margin-top: 40%;
  }
  #user-name-section {
    padding: 1.7rem 2rem 2.7rem 1rem;
  }
  #team-description-section {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 7px;
  }
`;

// const NotAvailable = styled.div`
//   position: relative;

//   ::after {
//     content: '';
//     padding: 40px 0;
//     position: absolute;
//     top: -20px;
//     left: -15px;
//     width: calc(100% + 30px);
//     height: 100%;
//     background-color: #e2e1e1;
//     opacity: 0.7;
//     z-index: 10;
//   }

//   h1 {
//     position: absolute;
//     top: 50%;
//     z-index: 10;
//     width: 100%;
//     text-align: center;
//     font-weight: 800;
//     font-size: 30px;
//     z-index: 11;
//     transform: translateY(-80%) rotate(-35deg);
//   }
// `;

const MyTeamDetails: React.FC<Props> = () => {
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { userProfileData, isLoading, teamDetails, status, totalTeamCoin, update } =
    useContext(UserContext);

  const {
    data: collectionData,
    isLoading: collectionLoading,
    isFetching: collectionFetching,
  } = useQuery(
    ['nftCollectionData', userProfileData?.id, teamDetails?.team?.id, currentPage],
    fetchNFTOwnedUser,
    {
      enabled: !!(userProfileData?.id && teamDetails?.team?.id),
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      cacheTime: 0,
      retry: 1,
    }
  );

  useEffect(() => {
    if (collectionData?.data?.total) {
      setTotalPages(collectionData?.data?.total);
    }
  }, [collectionData]);

  useIonViewWillEnter(() => {
    async function fetch() {
      await update();
    }
    fetch();
  }, []);

  return (
    <Wrapper authenticated={true}>
      <Container>
        <Loader isLoading={isLoading} className="loader">
          <div id="team-list">
            <TeamList data={[teamDetails?.team.logoImagePath]} onClick={() => {}} />
          </div>
          <div id="user-name-section">
            <UsernameCard
              avatar={
                userProfileData?.iconUrl
                  ? `${userProfileData?.iconUrl}?${Date.now()}`
                  : './assets/avatar/user-avatar.png'
              }
              color={teamDetails?.team?.teamColor}
              username={`${userProfileData?.name_sei} ${userProfileData?.name_mei}`}
            />
          </div>
          <div id="team-description-section">
            <TeamDescription
              status={
                status?.statusNameEnglish.charAt(0).toUpperCase() +
                  status?.statusNameEnglish.slice(1).toLowerCase() || '-'
              }
              level={teamDetails?.level ? teamDetails?.level : '-'}
              point={totalTeamCoin?.toLocaleString('en-US')}
              color={teamDetails?.team?.teamColor}
              totalCoin={
                userProfileData?.cumulativeTeamCoin
                  ? (Object.values(JSON.parse(userProfileData?.cumulativeTeamCoin))[0] as number)
                  : 0
              }
            >
              {/* <NotAvailable> */}
              {/* replace <NotAvailable> with <div> after market feature is available */}
              {/* <h1>Coming soon</h1> */}
              <Loader isLoading={collectionLoading || collectionFetching}>
                <MyCollections
                  color={teamDetails?.team?.teamColor}
                  data={collectionData?.data?.utilityNfts}
                  teamName={teamDetails?.team?.teamName} //Remove after confirmation as it is taken from teamDetail of context
                  teamLogo={teamDetails?.team?.primaryLogoImagePath} //Remove after confirmation as it is taken from teamDetail of context
                />
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages.toString()}
                />
              </Loader>
              {/* </NotAvailable> */}
            </TeamDescription>
          </div>
        </Loader>
      </Container>
    </Wrapper>
  );
};

export default PrivateRoute(MyTeamDetails);
