import React, { useContext } from 'react';
import styled from 'styled-components';
import { IonFooter, IonImg } from '@ionic/react';
import { useTranslation } from 'react-i18next';
// import { logoTwitter, logoInstagram } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { FooterMenu } from '../FooterMenu';

import { UserContext } from '../../../utils/UserContext';
import { AuthContext } from '../../../utils/AuthContext';
import { mobileContext } from '../../../utils/MobileContext';

interface IFooterProps {
  authenticated?: boolean;
  color: string;
  translate?: boolean;
}

const Foot = styled(IonFooter)`
  padding: 20px;
  padding-bottom: 0px;

  .icon {
    color: white;
    font-size: 22px;
  }

  ul {
    list-style: none;
    padding: 0;

    .logout {
      cursor: pointer;
    }

    li {
      margin-top: 17px;
      padding: 0;

      a {
        font-size: 15px;
        text-decoration: none;
        color: black;
      }
    }
  }

  .image-wrapper {
    margin-bottom: 20px;
  }

  .copyright-text {
    font-size: 10px;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 13px;
    text-align: center;
  }
`;

// const Icons = styled.div`
//   display: flex;
//   margin-top: 35px;
//   justify-content: space-between;

//   .icon {
//     cursor: pointer;
//     color: black;
//     font-size: 22px;
//     color: #4d4d4d;
//     padding: 10px;
//     border-radius: 50%;
//   }
// `;

const HomeWrapper = styled.div<{ isMobile: boolean }>`
  margin: 0 15px;
  .footer-wrap {
    padding: 33px 0px 33px 0px;
  }
  .copyright-text {
    text-align: center;
    font-size: 10px;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 13px;
  }

  @media (min-width: 450px) {
    ${(props) =>
      props.isMobile && {
        width: '100vw',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
  }
`;

export const Footer: React.FC<IFooterProps> = ({
  authenticated: authenticatedProps,
  color,
  translate = true,
}): any => {
  const { t } = useTranslation();

  const { isMobile } = mobileContext();
  const { authenticated, logout } = useContext(AuthContext);
  const { isFirstStepCompleted, isLoading, userProfileData } = useContext(UserContext);

  const membershipStatus = userProfileData?.membershipStatus?.items?.length === 0;
  if (
    (authenticatedProps && isFirstStepCompleted && !membershipStatus) ||
    (authenticated && isFirstStepCompleted && !membershipStatus)
  ) {
    return (
      <HomeWrapper isMobile={isMobile} translate={translate ? 'yes' : 'no'}>
        <FooterMenu color={color} />
        <div>
          <p className="copyright-text">Scala, Inc.</p>
        </div>
      </HomeWrapper>
    );
  }

  if (isLoading) {
    return '';
  }

  return (
    <Foot collapse="fade" className="ion-no-border" translate={translate ? 'yes' : 'no'}>
      <div className="image-wrapper">
        <IonImg src="./assets/images/logo.png" style={{ width: 90, height: 30 }} />
      </div>
      <ul>
        <li>
          <Link to={{ pathname: 'https://scalagrp.jp/company/' }} target="_blank">
            {t('About Company')}
          </Link>
        </li>
        <li>
          <Link to="/terms-and-conditions">{t('Terms & conditions')}</Link>
        </li>
        <li>
          <Link to="/privacy-policy">{t('Privacy policy')}</Link>
        </li>
        <li>
          <Link to="/specified-commercial-transactions">
            {t('Specified Commercial Transactions')}
          </Link>
        </li>
        <li>
          <Link to="/inquiry">{t('Inquiry')}</Link>
        </li>
        {authenticated && (!isFirstStepCompleted || (isFirstStepCompleted && membershipStatus)) && (
          <li className="logout" onClick={async () => await logout()}>
            {t('Logout')}
          </li>
        )}
      </ul>
      {/* Hide in initial Release */}
      {/* <Icons>
        <IonIcon src="/assets/icon/facebook-logo.svg" className="icon neomorphs-shadow-out" />
        <IonIcon icon={logoInstagram} className="icon neomorphs-shadow-out" />
        <IonIcon icon={logoTwitter} className="icon neomorphs-shadow-out" />
        <IonIcon src="/assets/icon/discord-logo.svg" className="icon neomorphs-shadow-out" />
      </Icons> */}
      <div>
        <p className="copyright-text">Scala, Inc.</p>
      </div>
    </Foot>
  );
};
